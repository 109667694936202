
import React, { useCallback } from 'react';
import { useSnackbar, SnackbarProvider, SnackbarProviderProps } from 'notistack';
import context, { NotifyFunction } from './context';

export type NotificationsProviderProps = SnackbarProviderProps;

const NotificationsProvider:React.FC<{ children?: React.ReactNode }> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const notify = useCallback<NotifyFunction>((msg, options) => {
    if (msg instanceof Error)
      enqueueSnackbar(msg.message, { variant: 'error', ...options });
    else
      enqueueSnackbar(msg, { variant: 'default', ...options });
  }, [enqueueSnackbar]);

  return (
    <context.Provider
      value={notify}
    >{props.children}</context.Provider>
  );
};

const DEFAULT_OPTIONS:SnackbarProviderProps = {
  autoHideDuration: 6000,
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
};

const Wrapper:React.FC<NotificationsProviderProps> = (props) => {
  const {
    children,
    ...snackbarProviderProps
  } = props;

  return (
    <SnackbarProvider {...DEFAULT_OPTIONS} {...snackbarProviderProps}>
      <NotificationsProvider>{children}</NotificationsProvider>
    </SnackbarProvider>
  )
}

export default Wrapper;
