
import apiClient from "../api-client";
import { ResponseData } from "@ademas/client";

const getOverviewReport = async <T>(from:Date, to:Date):Promise<T> => {
  const res = await apiClient.get<ResponseData<T>>(`/api/v1/dashboard?start_date=${from.toISOString()}&end_date=${to.toISOString()}`);
  return res.data.data;
};

export default getOverviewReport;
