
import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';

export type MessageTextProps = BoxProps & {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  typographyProps?: TypographyProps;
};

const MessageText:React.FC<MessageTextProps> = (props) => {
  const {
    startIcon,
    endIcon,
    children,
    typographyProps,
    sx,
    ...boxProps
  } = props;

  return (
    <Box sx={{ display: 'inline-flex', gap: 1, ...sx }} {...boxProps}>
      {startIcon && <span>{startIcon}</span>}
      <Typography textAlign="left" {...typographyProps}>{children}</Typography>
      {endIcon && <span>{endIcon}</span>}
    </Box>
  );
};

export default MessageText;
