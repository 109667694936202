
import { useLayoutEffect, useState } from 'react';
import { listObjectives } from '../services/objectives';
import { Objective } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export interface ObjectivesListHook {
  ():[Objective[]|null, boolean]
}

const useObjectivesList:ObjectivesListHook = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [objectives, setObjectives] = useState<Objective[]>([]);

  useLayoutEffect(() => {
    (async ()=>{
      try {
        setLoading(true);
        setObjectives(await listObjectives());
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [notify]);

  return [objectives, loading];
};

export default useObjectivesList;
