
import localforage from "localforage";

const authStore = localforage.createInstance({
  name: 'ademas',
  storeName: 'auth'
});

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_PROFILE_KEY = 'user_profile';

export const setAccessToken = (token:string) => authStore.setItem(ACCESS_TOKEN_KEY, token);
export const getAccessToken = () => authStore.getItem<string>(ACCESS_TOKEN_KEY);
export const dropAccessToken = () => authStore.removeItem(ACCESS_TOKEN_KEY);

export const setRefreshToken = (token:string) => authStore.setItem(REFRESH_TOKEN_KEY, token);
export const getRefreshToken = () => authStore.getItem<string>(REFRESH_TOKEN_KEY);
export const dropRefreshToken = () => authStore.removeItem(REFRESH_TOKEN_KEY);

export const setAuthUser = <T>(userProfile:T) => authStore.setItem(USER_PROFILE_KEY, userProfile);
export const getAuthUser = <T>() => authStore.getItem<T>(USER_PROFILE_KEY);
export const dropAuthUser = () => authStore.removeItem(USER_PROFILE_KEY);

export const dropAuthState = () => authStore.clear();
