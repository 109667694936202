
import apiClient from "../api-client";
import { ResponseData, ProductType } from '@ademas/client';

const listProducts = async () => {
  const res = await apiClient.get<ResponseData<ProductType[]>>('/api/v1/products');
  return res.data.data;
};

export default listProducts;
