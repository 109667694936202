
import React from 'react';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import GoogleMap, { GoogleMapProps } from '../@common/GoogleMap';

export type RegionsMapProps = GoogleMapProps;

const MAP_STYLES = [{
  featureType: "administrative",
  elementType: "geometry",
  stylers: [{
    visibility: "off"
  }]
}, {
  featureType: "administrative",
  elementType: "labels",
  stylers: [{
    visibility: "off"
  }]
}, {
  featureType: "administrative",
  elementType: "labels",
  stylers: [{
    color: "#C7A700"
  }]
}, {
  featureType: "administrative.locality",
  elementType: "labels",
  stylers: [{
    visibility: "simplified"
  }]
}, {
  featureType: "landscape",
  elementType: "geometry.fill",
  stylers: [{
    color: "#EDDF98"
  }]
}, {
  featureType: "landscape",
  elementType: "geometry.stroke",
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: "poi",
  elementType: "all",
  stylers: [{
    visibility: "off"
  }]
}, {
  featureType: "water",
  elementType: "all",
  stylers: [{
    color: "#F5F5F5"
  }]
}, {
  featureType: "road",
  elementType: "geometry",
  stylers: [{
    // color: "#F8EA9E"
    color: '#F5F5F5'
  }]
}, {
  featureType: "road",
  elementType: "geometry",
  stylers: [{
    visibility: 'simplified'
  }]
}, {
  featureType: "road",
  elementType: "labels",
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: "transit",
  elementType: "all",
  stylers: [{
    visibility: "off"
  }]
}];

const RegionsMap:React.FC<RegionsMapProps> = (props) => {
  return (
    <GoogleMapsWrapper apiKey={process.env.REACT_APP_MAPS_API_KEY as string}>
      <GoogleMap
        mapId="regions-map"
        mapTypeId="roadmap"
        backgroundColor="#F5F5F5"
        disableDefaultUI
        styles={MAP_STYLES}
        containerProps={{
          style: {
            minHeight: '100%',
            borderRadius: 8
          }
        }}
        {...props}
      />
    </GoogleMapsWrapper>
  );
};

export default RegionsMap;
