
import apiClient from "../api-client";
import { ResponseData, ChannelType } from '@ademas/client';

const listChannels = async () => {
  const res = await apiClient.get<ResponseData<ChannelType[]>>('/api/v1/channels');
  return res.data.data;
};

export default listChannels;
