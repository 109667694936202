
import { AxiosResponse } from 'axios';

const parseFileResponse = (res:AxiosResponse, defaultFileName='file') => {
  const fileName:string = (res.headers['content-disposition'] || "").split(/; */).reduce((n:string, meta:string) => {
    const parts = meta.split('=');
    
    if (parts[0] === 'filename')
      return parts[1]?.match(/"*(?<name>[^"]*)"*/)?.groups?.name;

    return n;
  }, '');

  return [URL.createObjectURL(res.data), fileName || defaultFileName];
};

export default parseFileResponse;
