
import apiClient from '../api-client';
import { ResponseData, MedicalStructure } from '@ademas/client';

const listMedicalStructures = async () => {
  const res = await apiClient.get<ResponseData<MedicalStructure[]>>('/api/v1/medical_structures');
  return res.data.data;
};

export default listMedicalStructures;
