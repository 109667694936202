
import apiClient from '../api-client';
import { ResponseData } from '@ademas/client';

const deleteUser = async (userId:number) => {
  const res = await apiClient.delete<ResponseData<any>>(`/api/v1/users/delete?id=${userId}`);
  return res.data.data;
};

export default deleteUser;
