
import apiClient from '../api-client';
import { ResponseData } from '@ademas/client';

type PasswordResetCredentials = {
  password_token: string;
};

const verifyOTP = async (otp:string) => {
  const res = await apiClient.post<ResponseData<PasswordResetCredentials>>('/api/v1/users/verify_code', { otp_code: otp });
  return await res.data.data.password_token as string;
};

export default verifyOTP;
