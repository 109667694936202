
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export type ReportTableCellProps = TableCellProps & {
  fontStyle?: string;
  fontWeight?: string;
  opacity?: number;
};

const ReportTableCell = styled(TableCell)<ReportTableCellProps>(({ theme, ...props }) => ({
  borderBottom: '1px solid #F4F2F2',
  borderRight: '1px solid #F4F2F2',
  minWidth: 100,
  whiteSpace: 'nowrap',
  fontStyle: props.fontStyle,
  fontWeight: props.fontWeight,
  opacity: props.opacity,
  '&:last-child': { borderRight: 'none' },
  '&.MuiTableCell-sizeSmall': {
    paddingBlock: theme.spacing(1)
  },
  '&.MuiTableCell-head': {
    ...theme.typography.subtitle1,
    color: theme.palette.primary.main,
    fontWeight: 600,
    borderRight: 'none',
    '&.MuiTableCell-sizeSmall': {
      paddingBlock: theme.spacing(1.5)
    }
  }
}));

export default ReportTableCell;
