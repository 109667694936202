
import { useLayoutEffect, useState } from 'react';
import { listChannels } from '../services/channels';
import { ChannelType } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export interface ChannelsListHook {
  ():[ChannelType[], boolean]
}

const useChannelsList:ChannelsListHook = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ChannelType[]>([]);

  useLayoutEffect(() => {
    (async ()=>{
      try {
        setLoading(true);
        setList((await listChannels()).sort((a, b) => {
          if (a.type === b.type)
            return 0;
          else if (a.type > b.type)
            return 1;
          else
            return -1;
        }));
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [notify]);

  return [list, loading];
};

export default useChannelsList;
