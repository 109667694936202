
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ReportTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  paddingBlock: theme.spacing(1.5),
  paddingInline: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600
}));

export default ReportTitle;
