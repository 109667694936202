
import React from 'react';
import dateRangeContext from './context';

import { useState } from 'react';

export interface DateRange {
  from: Date;
  to: Date;
}

export interface DateRangeProviderProps {
  initialValue?: DateRange;
  children?: React.ReactNode;
}

const DateRangeProvider: React.FC<DateRangeProviderProps> = props => {
  const {
    initialValue={
      from: new Date(),
      to: new Date()
    }
  } = props;

  const [range, setRange] = useState<DateRange>(() => {
    let from = initialValue.from;
    let to = initialValue.to;
    from = new Date(from.toDateString());
    to = new Date(to.toDateString());
    to.setDate(to.getDate() + 1);
    to.setMilliseconds(-1);

    return {from, to};
  });

  return (
    <dateRangeContext.Provider value={{ ...range, update: (from, to) => setRange(currRange=>({from: from || currRange.from, to: to || currRange.to})) }}>
      {props.children}
    </dateRangeContext.Provider>
  );
};

export default DateRangeProvider;
