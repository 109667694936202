
import apiClient from '../api-client';
import { ResponseData, UserProfile, UserProfileData, UserPasswordData } from '@ademas/client';

const newUser = async (data:UserProfileData&UserPasswordData) => {
  const res = await apiClient.post<ResponseData<UserProfile>>('/api/v1/users/create', data);
  return res.data.data;
};

export default newUser;
