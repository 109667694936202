
import apiClient from '../api-client';
import { ResponseData, ObjectiveData, Objective } from '@ademas/client';

const createObjective = async (data:ObjectiveData) => {
  const res = await apiClient.post<ResponseData<Objective>>('/api/v1/objectives', data);
  return res.data;
};

export default createObjective;
