
import apiClient from "../api-client";
import { ResponseData, PromotionActivityReport } from '@ademas/client';
import parseFileResponse from "../utils/parse-file-response";

const getPromotionActivityReport = async (from:Date, to:Date) => {
  const res = await apiClient.get<ResponseData<PromotionActivityReport>>(`/api/v1/promotor_submissions?start_date=${from.toISOString()}&end_date=${to.toISOString()}`);
  return res.data.data;
};

export const exportPromotionActivityReport = async (from:Date, to:Date) => {
  const res = await apiClient.get(`/api/v1/promotor_submissions/export?start_date=${from.toISOString()}&end_date=${to.toISOString()}`, {
    responseType: 'blob'
  });
  return parseFileResponse(res, 'report');
};

export default getPromotionActivityReport;
