
import axios, { AxiosRequestConfig } from 'axios';
import HttpError from './HttpError';
import { getRefreshToken, setRefreshToken, setAccessToken, dropAuthState } from './auth/store';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST
});

export type RefreshTokenRequest = null|AxiosRequestConfig;

let refreshTokenRequest:RefreshTokenRequest = null;
let pendingRequest:Promise<void>|null = null;

async function refreshTokens () {
  if (!refreshTokenRequest) {
    refreshTokenRequest = {
      ...apiClient.defaults,
      url: '/api/v1/users/tokens',
      method: 'post',
      headers: {
        ...apiClient.defaults.headers.common,
        'Refresh-Token': await getRefreshToken()
      }
    };
  }

  if (pendingRequest)
    return pendingRequest;

  return pendingRequest = axios(refreshTokenRequest).then((res) => ({
    access_token: res.headers['access-token'],
    refresh_token: res.headers['refresh-token']
  })).then(({access_token, refresh_token}) => {
    apiClient.defaults.headers.common.Authorization = access_token;

    return Promise.all([
      setAccessToken(access_token),
      setRefreshToken(refresh_token)
    ]).then(()=>void 0);
  }).catch(() => {
    return dropAuthState()
      .then(() => window.location.reload())
      .then(() => {throw new Error('Session expired')})
    ;
  }).finally(() => {
    pendingRequest = null;
    refreshTokenRequest = null;
  });
}

/**
 * Intercept 401 and refresh auth tokens.
 */
apiClient.interceptors.response.use(undefined, async (err) => {
  const originalRequest = err.config;

  if (err.response && err.response.status === 401) {
    await refreshTokens();
    delete originalRequest.headers['Authorization'];
    return apiClient(originalRequest);
  }

  throw err;
});

apiClient.interceptors.response.use(undefined, (err) => {
  if (!err.response || !err.response.data)
    throw err;

  throw new HttpError(err.response.data.message, { status: err.response.status });
});

export default apiClient;
