
import React from 'react';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useState } from 'react';

export type PasswordTextFieldProps = OutlinedInputProps & {
  iconButtonProps?: IconButtonProps;
};

const PasswordTextField:React.FC<PasswordTextFieldProps> = (props) => {
  const {
    type, // Extracted to ignore override.
    iconButtonProps,
    ...textFieldProps
  } = props;

  const [visible, setVisible] = useState(false);

  return (
    <OutlinedInput
      type={visible ? 'text' : 'password'}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            tabIndex={-1}
            size="large"
            {...iconButtonProps}
            edge="end"
            onClick={()=>setVisible(currVal=>!currVal)}
          >
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      )}
      {...textFieldProps}
    />
  );
};

export default PasswordTextField;
