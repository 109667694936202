
import apiClient from '../api-client';
import { ResponseData, PointOfSale } from '@ademas/client';

const listPointOfSales = async () => {
  const res = await apiClient.get<ResponseData<PointOfSale[]>>('/api/v1/point_of_sales');
  return res.data.data;
};

export default listPointOfSales;
