
import React from 'react';
import { Marker, MarkerProps, InfoWindow } from '../@lib/components/@common/GoogleMap';
import { MedicalStructure } from '@ademas/client';
import { useTranslation } from 'react-i18next';

export type MedicalStructureMarkerProps = MarkerProps & {
  medicalStructure: MedicalStructure;
};

const MED_STRCT_MARKER_ICON = {
  path: 'M 0, 5 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0 Z',
  fillColor: '#3DD294',
  fillOpacity: 1,
  strokeOpacity: 0
};

const MedicalStructureMarker:React.FC<MedicalStructureMarkerProps> = (props) => {
  const {
    medicalStructure:medStrct,
    children,
    ...markerProps
  } = props;

  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <Marker
      position={medStrct.location}
      icon={MED_STRCT_MARKER_ICON}
      {...markerProps}
    >
      <InfoWindow>
        <div style={{ minWidth: 250, maxWidth: 300, borderRadius: 8, overflow: 'auto' }}>
          <div
            style={{
              backgroundColor: '#3DD294',
              color: '#ffffff',
              fontSize: 18,
              fontWeight: 400,
              padding: 16
            }}
          >{t('health_service_point')}</div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('name', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ medStrct.structure_name || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('first_name', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ medStrct.nom_prenom || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('function', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ medStrct.function || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('department', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ medStrct.department || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14 }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('address', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ medStrct.address || '--' }</p>
          </div>

        </div>
      </InfoWindow>
    </Marker>
  );
};

export default MedicalStructureMarker;
