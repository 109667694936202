
import apiClient from '../api-client';
import { ResponseData, UserProfile, UserProfileData } from '@ademas/client';

const updateUser = async (userId:number, data:UserProfileData) => {
  const res = await apiClient.put<ResponseData<UserProfile>>(`/api/v1/users/update?id=${userId}`, data);
  return res.data.data;
};

export default updateUser;
