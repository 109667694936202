
import React, { FormEventHandler } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import SplitTextField from '../../@lib/components/@common/SplitTextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LanguageSwitch from '../../@lib/components/LanguageSwitch';
import { Trans } from 'react-i18next';

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { verifyOTP } from '../../@lib/services/auth';

const OTPVerification:React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['login', 'common']);
  const notify = useNotify();
  const [verifyingOTP, setVerifyingOTP] = useState(false);

  const handleOTPVerification:FormEventHandler<HTMLFormElement> = useCallback(async (ev) => {
    try {
      ev.preventDefault();
      setVerifyingOTP(true);
      const formData = new FormData(ev.currentTarget);
      const data = Object.fromEntries(formData) as { otp: string };
      const passwordToken = await verifyOTP(data.otp);
      notify('Code verified!', { variant: 'success' });
      navigate('/auth/reset', {
        state: { passwordToken },
        replace: true
      });
    }
    catch(err) {
      notify(err as Error);
      setVerifyingOTP(false);
    }

  }, [navigate, notify]);

  const disabled = verifyingOTP;

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center', pt: { xs: 12, md: 18 }, pb: { xs: 12, md: 0 } }}>
      <Grid container spacing={{ xs: 5, md: 18 }} justifyContent="center">
        <Grid item xs={12} md={5}>
          <Typography variant="h3" color="white" mb={3} mt={{ md: 14 }}>{t('welcome.title')}</Typography>
          <Typography color="white" mb={2}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Excepturi fugit vel obcaecati quas aspernatur officiis voluptatibus perferendis id quod provident.
          </Typography>
          <Typography color="white">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Excepturi fugit vel obcaecati quas aspernatur officiis voluptatibus perferendis id quod provident.
            Sed eum libero reprehenderit sit voluptatibus totam? Voluptates, laboriosam sunt.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ position: 'relative', px: { xs: 2, md: 6 }, py: { xs: 10, md: 10 }, borderRadius: 4 }}>
            <LanguageSwitch
              persistent
              dropDownArrowPosition="left"
              sx={{
                position: 'absolute',
                top: 20,
                right: 20
              }}
            />
            <Typography textAlign="center" variant="h5" mb={4}><Trans i18nKey="form.forgot_password.title" t={t} /></Typography>
            <form onSubmit={handleOTPVerification}>
              <Box sx={{mt: 4, mb: 2}}>
                <FormLabel>{t('form.forgot_password.otp.label')}</FormLabel>
                <SplitTextField
                  name="otp"
                  autoFocus
                  fullWidth
                  required
                  disabled={disabled}
                />
              </Box>

              <Box mt={6} position="relative">
                <Button
                  type="submit"
                  disabled={disabled}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  {verifyingOTP ? <CircularProgress size={28}/> : t('verify', { ns: 'common' })}
                </Button>

                <Box mt={3} position="absolute" width="100%" textAlign="center">
                  <Link component={RouterLink} to="/auth/login" variant="caption">{t('login_instead', { ns: 'common' })}</Link>
                </Box>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OTPVerification;
