
import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { StaticDatePicker } from '@mui/x-date-pickers';

import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

export interface DateRange {
  from: Date;
  to: Date;
}

export interface CustomRangePickerDialogProps extends DialogProps {
  initialValues?: DateRange;
  onDone: (range: DateRange)=>void;
  onCancel: ()=>void;
}


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  );
}

const CustomRangePickerDialog: React.FC<CustomRangePickerDialogProps> = props => {
  const {
    initialValues = { from: new Date(), to: new Date() },
    onDone,
    onCancel,
    children,
    ...dialogProps
  } = props;

  const [dateRange, setDateRange] = useState<DateRange>(initialValues);
  const [currTab, setCurrTab] = useState<'from-date' | 'to-date'>('from-date');
  // Update initial value on dialog open
  useEffect(()=>{
    if(dialogProps.open){
      setDateRange(initialValues);
      setCurrTab('from-date');
    }

  }, [dialogProps.open, initialValues]);

  return (
    <Dialog
      disableEscapeKeyDown
      {...dialogProps}
    >
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={currTab}
        onChange={(ev, val)=>setCurrTab(val)}
        // Set indicator height to 0.
      >
        <Tab
          label={<><small style={{ opacity: 0.5 }}>From</small>{dayjs(dateRange.from).format('DD MMM YYYY')}</>}
          value="from-date"
        />
        <Tab
          label={<><small style={{ opacity: 0.5 }}>To</small>{dayjs(dateRange.to).format('DD MMM YYYY')}</>}
          value="to-date"
        />
      </Tabs>
      <DialogContent sx={{ p: 0 }}>
        <TabPanel value="from-date" index={currTab}>
          <StaticDatePicker
            value={dayjs(dateRange.from)}
            onChange={date=>date && setDateRange(curr=>({...curr, from: date.toDate()}))}
            shouldDisableDate={(date) => date.isAfter(dateRange.to)}
            slotProps={{
              toolbar: {
                hidden: true
              },
              actionBar: {
                actions: []
              }
            }}
          />
        </TabPanel>
        <TabPanel value="to-date" index={currTab}>
          <StaticDatePicker
            value={dayjs(dateRange.to)}
            onChange={date=>date && setDateRange(curr=>({...curr, to: date.toDate()}))}
            disableFuture
            shouldDisableDate={(date) => date.isBefore(dateRange.from)}
            slotProps={{
              toolbar: {
                hidden: true
              },
              actionBar: {
                actions: []
              }
            }}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>onDone(dateRange)}
        >Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomRangePickerDialog;
