
import React from 'react';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

export type TableRowSkeletonProps = SkeletonProps & {
  rowsCount?: number;
  cellsCount?: number;
  tableRowComponent?: React.ComponentType<TableRowProps>;
  tableCellComponent?: React.ComponentType<TableCellProps>;
  tableRowProps?: TableRowProps;
  tableCellProps?: TableCellProps;
};

const TableRowSkeleton:React.FC<TableRowSkeletonProps> = (props) => {
  const {
    rowsCount=1,
    cellsCount=1,
    tableRowComponent:TableRowComponent=TableRow,
    tableCellComponent:TableCellComponent=TableCell,
    tableRowProps,
    tableCellProps,
    ...skeletonProps
  } = props;

  return (
    <React.Fragment>
      {Array(rowsCount).fill('').map((_, i) => (
        <TableRowComponent key={`${i}`} {...tableRowProps}>
          {Array(cellsCount).fill('').map((_, j) => (
            <TableCellComponent key={`${i}-${j}`} {...tableCellProps}>
              <Skeleton variant="text" {...skeletonProps} />
            </TableCellComponent>
          ))}
        </TableRowComponent>
      ))}
    </React.Fragment>
  );
};

export default TableRowSkeleton;
