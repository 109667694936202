
import React from 'react';
import { ObjectiveData, Objective } from '@ademas/client';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import MainToolbar from '../../@lib/components/MainToolbar';
import EditObjectiveDialog from '../../@lib/components/EditObjectiveDialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import useObjectivesList from '../../@lib/hooks/useObjectivesList';
import useChannelsList from '../../@lib/hooks/useChannelsList';
import useProductsList from '../../@lib/hooks/useProductsList';
import { newObjective, updateObjective, deleteObjective } from '../../@lib/services/objectives';

const Objectives:React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  const notify = useNotify();
  const [data, loading] = useObjectivesList();
  const [channels] = useChannelsList();
  const [products] = useProductsList();
  const [objectiveToEdit, setObjectiveToEdit] = useState<Objective>();
  const [objDialogOpen, setObjDialogOpen] = useState(false);
  const [savingObjective, setSavingObjective] = useState(false);

  const channelOptions = useMemo(() => channels.map((channel) => ({ label: channel.type, value: channel.type })), [channels]);
  const productOptions = useMemo(() => products.map((product) => ({ label: product.product_name, value: product.product_name })), [products]);

  const handleAddNewObjective = useCallback(async (data:ObjectiveData) => {
    try {
      setSavingObjective(true);
      await newObjective(data);
      setObjDialogOpen(false);
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingObjective(false);
    }
  }, [notify]);

  const handleSaveObjective = useCallback(async (data:ObjectiveData) => {
    try {
      if (!objectiveToEdit)
        return;

      setSavingObjective(true);
      await updateObjective(objectiveToEdit.id, data);
      setObjectiveToEdit(undefined);
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingObjective(false);
    }
  }, [objectiveToEdit, notify]);

  const handleDeleteObjective = useCallback(async (objective:Objective) => {
    try {
      setSavingObjective(true);
      if(!window.confirm(`${t('msg.delete_objective')} "${objective.title}". ${t('msg.confirm')}`))
        return;
      
      await deleteObjective(objective.id);
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingObjective(false);
    }
  }, [t, notify]);

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('settings', { ns: 'common' })} />
      {/* BEGIN: New objective dialog */}
      <EditObjectiveDialog
        title={t('objectives.add_new.label')}
        actionButtonText={t('add', { ns: 'common' })}
        productOptions={productOptions}
        channelOptions={channelOptions}
        initialData={{ year: new Date().getFullYear() }}
        open={objDialogOpen}
        onClose={() => setObjDialogOpen(false)}
        onSave={handleAddNewObjective}
        disabled={savingObjective}
        disableEscapeKeyDown
      />
      {/* BEGIN: Edit objective dialog */}
      <EditObjectiveDialog
        title={t('objectives.edit.label')}
        actionButtonText={t('save', { ns: 'common' })}
        productOptions={productOptions}
        channelOptions={channelOptions}
        initialData={objectiveToEdit}
        open={!!objectiveToEdit}
        onClose={() => setObjectiveToEdit(undefined)}
        onSave={handleSaveObjective}
        disabled={savingObjective}
        disableEscapeKeyDown
      />

      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Typography variant="h5">{t('objectives', { ns: 'common' })}</Typography>
        <Button
          color="secondary"
          onClick={() => setObjDialogOpen(true)}
          disabled={loading || savingObjective}
        >{t('add_new', { ns: 'common' })}</Button>
      </Stack>

      <Typography variant="caption" color="text.secondary">{t('objectives.annual')}</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell>{t('product', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('year', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('label', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('target', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('channel', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((o, i) => (
              <TableRow key={`${i}`} hover>
                <ReportTableCell>{o.product}</ReportTableCell>
                <ReportTableCell>{o.year}</ReportTableCell>
                <ReportTableCell>{o.title}</ReportTableCell>
                <ReportTableCell>{o.target_value}</ReportTableCell>
                <ReportTableCell>{o.channel}</ReportTableCell>
                <ReportTableCell padding="checkbox">
                  <IconButton disabled={savingObjective} size="large" onClick={() => setObjectiveToEdit(o)}><EditIcon color="primary" /></IconButton>
                  <IconButton disabled={savingObjective} size="large" onClick={() => handleDeleteObjective(o)}><DeleteIcon color="error" /></IconButton>
                </ReportTableCell>
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={5} tableCellComponent={ReportTableCell} />}
            {data && data.length === 0 && (
              <TableRow>
                <ReportTableCell colSpan={4} opacity={0.5}>{t('no_data', { ns: 'common' })}</ReportTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Objectives;
