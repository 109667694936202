
import apiClient from '../api-client';
import { ResponseData } from '@ademas/client';

type PartialUserProfile = {
  username: string;
};

const resetPassword = async (newPassword:string, token:string) => {
  const res = await apiClient.post<ResponseData<PartialUserProfile>>('/api/v1/users/reset_password', {
    password_token: token,
    password: newPassword,
    password_confirmation: newPassword
  });

  return res.data.data;
};

export default resetPassword;
