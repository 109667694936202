
import React from 'react';
import { useState, useEffect } from 'react';
import useGoogleMap from '../useGoogleMap';
import context from './context';

export type MarkerProps = Omit<google.maps.MarkerOptions, 'position'> & {
  position?: google.maps.MarkerOptions["position"] | string;
  updateBounds?: boolean;
  children?: React.ReactNode;
};

function parsePosition (rawPosition:any) {
  let position:google.maps.LatLngLiteral|null = null;
  if (typeof rawPosition === 'string') {
    const parts = rawPosition.split(' ');
    if (parts.length >= 2) {
      const lat = parseInt(parts[0]);
      const lng = parseInt(parts[1]);

      if (!isNaN(lat) && !isNaN(lng))
        position = { lat, lng };
      else
        position = null;
    }
    else
      position = null;

    return position;
  }
  else
    return rawPosition;
}

const Marker:React.FC<MarkerProps> = (props) => {
  const {
    updateBounds,
    children,
    ...markerOptions
  } = props;

  const {map, bounds} = useGoogleMap();
  const [marker, setMarker] = useState<google.maps.Marker|null>(null);

  useEffect(() => {
    let _marker:google.maps.Marker|null = null;
    const position = parsePosition(markerOptions.position);

    if (!position || !map)
      return;

    if (!marker) {
      _marker = new window.google.maps.Marker({
        ...markerOptions,
        map,
        position
      });

      setMarker(_marker);
    }
    else {
      _marker = marker;
      marker.setOptions({
        ...markerOptions,
        map,
        position
      });
    }

    if (bounds && updateBounds) {
      bounds.extend(position);
      map.fitBounds(bounds);
    }

    return () => { _marker && _marker.setMap(null); }
  }, [marker, markerOptions, map, bounds, updateBounds]);

  return (
    <context.Provider value={marker}>{children}</context.Provider>
  );
};

export default Marker;
