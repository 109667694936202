
import React from 'react';
import { ObjectiveData } from '@ademas/client';
import Stack from '@mui/material/Stack';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import NativeSelect from '@mui/material/NativeSelect';
import { DatePicker } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
dayjs.extend(arraySupport);

export type OptionType = {
  label: string;
  value: string;
};

export type EditObjectiveDialogProps = DialogProps & {
  initialData?: Partial<ObjectiveData>;
  title?: string;
  channelOptions?: OptionType[];
  productOptions?: OptionType[];
  actionButtonText?: string;
  disabled?: boolean;
  onSave?: (data:ObjectiveData) => void;
  onClose?: () => void;
};

const EditObjectiveDialog:React.FC<EditObjectiveDialogProps> = (props) => {
  const {
    initialData={},
    title="Edit Objective",
    channelOptions=[],
    productOptions=[],
    actionButtonText="Save",
    onSave,
    disabled,
    children,
    ...dialogProps
  } = props;

  const { t } = useTranslation(['settings', 'common']);

  const handleSave:React.FormEventHandler<HTMLFormElement> = useCallback((ev) => {
    ev.preventDefault();
    if (!onSave)
      return;

    const formData = new FormData(ev.currentTarget);
    // TODO: Fix type any
    const objective:ObjectiveData = Object.fromEntries(formData) as any;
    onSave(objective);
  }, [onSave]);

  return (
    <Dialog
      disableEscapeKeyDown
      {...dialogProps}
      PaperProps={{
        component: 'form',
        onSubmit: handleSave
      }}
    >
      <DialogTitle color="primary">{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} py={2}>
          <div>
            <FormLabel>{t('product', { ns: 'common' })}</FormLabel>
            <NativeSelect
              name="product"
              input={<OutlinedInput />}
              IconComponent={ExpandMoreIcon}
              fullWidth
              defaultValue={initialData.product}
              disabled={disabled}
            >
              <option value="">{t('none', { ns: 'common' })}</option>
              {productOptions.map((opt, i) => (
                <option key={`${opt.value}-${i}`} value={opt.value}>{opt.label}</option>
              ))}
            </NativeSelect>
          </div>
          <div>
            <FormLabel>{t('year', { ns: 'common' })}</FormLabel>
            <DatePicker
              name="year"
              defaultValue={dayjs([initialData.year])}
              views={['year']}
              /**
               * BUG: `disablePast` renders the picker in error state.
               */
              // disablePast
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true
                }
              }}
              disabled={disabled}
            />
          </div>
          <div>
            <FormLabel>{t('title', { ns: 'common' })}</FormLabel>
            <OutlinedInput
              name="title"
              defaultValue={initialData.title}
              placeholder={t('objectives.target.label')}
              required
              fullWidth
              disabled={disabled}
            />
          </div>

          <div>
            <FormLabel>{t('target', { ns: 'common' })}</FormLabel>
            <OutlinedInput
              name="target_value"
              defaultValue={initialData.target_value}
              type="number"
              required
              fullWidth
              disabled={disabled}
            />
          </div>

          <div>
            <FormLabel>{t('channel', { ns: 'common' })}</FormLabel>
            <NativeSelect
              name="channel"
              input={<OutlinedInput />}
              IconComponent={ExpandMoreIcon}
              fullWidth
              defaultValue={initialData.channel}
              disabled={disabled}
            >
              <option value="">{t('none', { ns: 'common' })}</option>
              {channelOptions.map((opt, i) => (
                <option key={`${opt.value}-${i}`} value={opt.value}>{opt.label}</option>
              ))}
            </NativeSelect>
          </div>
          
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={dialogProps.onClose}
          disabled={disabled}
        >{t('cancel', { ns: 'common' })}</Button>

        <Button
          type="submit"
          disabled={disabled}
        >{actionButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditObjectiveDialog;
