
import { useState, useLayoutEffect } from 'react';
import { getOverviewReport } from '../services/reports';
import { PromoterOverviewReport } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export type ReportData = PromoterOverviewReport;

export type DateRange = {
  from:Date;
  to:Date;
};

export interface OverviewReportHook {
  (dateRange:DateRange):[(ReportData|null), boolean]
}

const usePromoterOverviewReport:OverviewReportHook = (dateRange) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<ReportData|null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rawData = await getOverviewReport<PromoterOverviewReport>(dateRange.from, dateRange.to);
        setReportData(rawData);
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [dateRange, notify]);

  return [reportData, loading];
};

export default usePromoterOverviewReport;
