
import React from 'react';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import ReportTitle from '../../@lib/components/@common/ReportTitle';
import Alert from '@mui/material/Alert';
import DateRangePicker from '../../@lib/components/@common/DateRangePicker';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useState } from 'react';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { useDateRange } from '../../@lib/components/@common/DateRangePicker';
import useInterpersonalCommunicationsReport from '../../@lib/hooks/useInterpersonalCommunicationsReport';
import { exportInterpersonalCommunicationsReport } from '../../@lib/services/reports';

const InterpersonalCommunicationsReport:React.FC = () => {
  const { t } = useTranslation(['reports', 'common']);
  const notify = useNotify();
  const range = useDateRange();
  const [data, loading] = useInterpersonalCommunicationsReport(range);
  const [exportingData, setExportingData] = useState(false);

  const renderData = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('data_report', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <ReportTitle>{t('interpersonal_comm.title.sales_breakdown')}</ReportTitle>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          color="secondary"
          disabled={exportingData}
          onClick={async () => {
            try {
              setExportingData(true);
              const [url, filename] = await exportInterpersonalCommunicationsReport(range.from, range.to);
              const link = document.createElement('a');
              link.setAttribute('href', url);
              link.setAttribute('download', filename);
              link.click();
            }
            catch (err) {
              notify(err as Error);
            }
            finally {
              setExportingData(false);
            }
          }}
        >{t('export', { ns: 'common' })}</Button>
      </Stack>

      <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('product', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData && data.products.map((_, i) => (
              <TableRow key={`${i}`} hover>
                <ReportTableCell>{_.product_name}</ReportTableCell>
                <ReportTableCell>{_.total_sold}</ReportTableCell>
              </TableRow>
            ))}

            {renderData && data.products.length === 0 && (
              <TableRow>
                <ReportTableCell opacity={0.5}>{t('no_data', { ns: 'common' })}</ReportTableCell>
              </TableRow>
            )}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <ReportTitle>{t('interpersonal_comm.title.activity_by_actor')}</ReportTitle>
          </Stack>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <ReportTableCell>{t('organization')}</ReportTableCell>
                  <ReportTableCell
                    colSpan={(renderData && data.organisation_activites.length > 0) ? data.organisation_activites[0].data.length-1 : 1}
                  >{t('activities')}</ReportTableCell>
                  <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderData && data.organisation_activites.length > 0 && (
                  <TableRow>
                    <ReportTableCell />
                    {data.organisation_activites[0].data.map((act, i) => (
                      <ReportTableCell key={`${i}`} opacity={0.75}>{act.type_activity_name}</ReportTableCell>
                    ))}
                  </TableRow>
                )}

                {renderData && data.organisation_activites.map((_, i) => (
                  <TableRow key={`${i}`} hover>
                    <ReportTableCell sx={{ verticalAlign: 'top' }}>{_.organisation_name}</ReportTableCell>
                    {_.data.map((activity, j) => 'type_activity_name' in activity ? (
                      <ReportTableCell key={`${i}-${j}`}>{activity.number_activity}</ReportTableCell>
                    ) : (
                      <ReportTableCell key={`${i}-${j}`}>{activity.total}</ReportTableCell>
                    ))}
                  </TableRow>
                ))}

                {renderData && data.organisation_activites.length === 0 && (
                  <TableRow>
                    <ReportTableCell opacity={0.5}>{t('no_data', { ns: 'common' })}</ReportTableCell>
                  </TableRow>
                )}

                {loading && <TableRowSkeleton rowsCount={3} cellsCount={5} tableCellComponent={ReportTableCell} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <ReportTitle>{t('interpersonal_comm.title.sales_by_actor')}</ReportTitle>
          </Stack>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <ReportTableCell>{t('organization')}</ReportTableCell>
                  <ReportTableCell
                    colSpan={(renderData && data.organisation_sales.length > 0) ? data.organisation_sales[0].data.length-1 : 1}
                  >{t('products', { ns: 'common' })} / {t('qty_sold')}</ReportTableCell>
                  <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderData && data.organisation_sales.length > 0 && (
                  <TableRow>
                    <ReportTableCell />
                    {data.organisation_sales[0].data.map((_, i) => (
                      <ReportTableCell key={`${i}`} opacity={0.75}>{_.product_name}</ReportTableCell>
                    ))}
                  </TableRow>
                )}

                {renderData && data.organisation_sales.map((_, i) => (
                  <TableRow key={`${i}`} hover>
                    <ReportTableCell sx={{ verticalAlign: 'top' }}>{_.organisation_name}</ReportTableCell>
                    {_.data.map((d, j) => 'product_name' in d ? (
                      <ReportTableCell key={`${i}-${j}`}>{d.sold_amount}</ReportTableCell>
                    ) : (
                      <ReportTableCell key={`${i}-${j}`}>{d.total}</ReportTableCell>
                    ))}
                  </TableRow>
                ))}

                {renderData && data.organisation_sales.length === 0 && (
                  <TableRow>
                    <ReportTableCell opacity={0.5}>{t('no_data', { ns: 'common' })}</ReportTableCell>
                  </TableRow>
                )}

                {loading && <TableRowSkeleton rowsCount={3} cellsCount={5} tableCellComponent={ReportTableCell} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <ReportTitle>{t('interpersonal_comm.title.targets_by_activity')}</ReportTitle>
          </Stack>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <ReportTableCell width={200}>{t('designation')}</ReportTableCell>
                  <ReportTableCell colSpan={4}>{t('women')}</ReportTableCell>
                  <ReportTableCell colSpan={4}>{t('men')}</ReportTableCell>
                  <ReportTableCell colSpan={4}>{t('disabled')}</ReportTableCell>
                  <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <ReportTableCell />
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
                  <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
                  <ReportTableCell />
                </TableRow>
                {renderData && data.activity_targets.map((_, i) => (
                  <React.Fragment key={`${i}`}>
                    {_.data.map((d, j) => (
                      <TableRow key={`${i}-${j}`} hover>
                        {j === 0 && <ReportTableCell sx={{ verticalAlign: 'top' }} rowSpan={_.data.length}>{_.activity_name}</ReportTableCell>}
                        <ReportTableCell>{d.female_15_18}</ReportTableCell>
                        <ReportTableCell>{d.female_19_24}</ReportTableCell>
                        <ReportTableCell>{d.female_25_49}</ReportTableCell>
                        <ReportTableCell>{d.total_female}</ReportTableCell>
                        <ReportTableCell>{d.male_15_18}</ReportTableCell>
                        <ReportTableCell>{d.male_19_24}</ReportTableCell>
                        <ReportTableCell>{d.male_25_49}</ReportTableCell>
                        <ReportTableCell>{d.total_male}</ReportTableCell>
                        <ReportTableCell>{d.disabled_15_18}</ReportTableCell>
                        <ReportTableCell>{d.disabled_19_24}</ReportTableCell>
                        <ReportTableCell>{d.disabled_25_49}</ReportTableCell>
                        <ReportTableCell>{d.total_disabled}</ReportTableCell>
                        <ReportTableCell>{d.total}</ReportTableCell>
                      </TableRow>
                    ))}
                    {_.data.length === 0 ? (
                      <TableRow hover>
                        <ReportTableCell>{_.activity_name}</ReportTableCell>
                        <ReportTableCell colSpan={12}><Alert severity="info" color="warning">{t('no_data', { ns: 'common' })}</Alert></ReportTableCell>
                        <ReportTableCell />
                      </TableRow>
                    ) : (
                      <TableRow key={`${i}-total`} sx={{ backgroundColor: '#fbfbfb' }}>
                        <ReportTableCell size="medium">{t('total', { ns: 'common' })}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_female_15_18}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_female_19_24}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_female_25_49}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_female}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_male_15_18}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_male_19_24}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_male_25_49}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_male}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_disabled_15_18}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_disabled_19_24}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_disabled_25_49}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total_disabled}</ReportTableCell>
                        <ReportTableCell>{_.total[0].total}</ReportTableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}

                {loading && <TableRowSkeleton rowsCount={3} cellsCount={13} tableCellComponent={ReportTableCell} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>Breakdown of media distributed by type</ReportTitle>
      </Stack>

      <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>Support type</ReportTableCell>
              <ReportTableCell>Number of media distributed</ReportTableCell>
              <ReportTableCell>Location</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1,2,3,4,5,6,7].map((_, i) => (
              <TableRow key={`${i}`} hover>
                <ReportTableCell>None</ReportTableCell>
                <ReportTableCell>None</ReportTableCell>
                <ReportTableCell>None</ReportTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>Breakdown of new outlets by type</ReportTitle>
      </Stack>

      <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>Sales outlet type</ReportTableCell>
              <ReportTableCell>Manager's name</ReportTableCell>
              <ReportTableCell>Address</ReportTableCell>
              <ReportTableCell>Telephone number</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1,2,3,4,5,6,7].map((_, i) => (
              <TableRow key={`${i}`} hover>
                <ReportTableCell>None</ReportTableCell>
                <ReportTableCell>None</ReportTableCell>
                <ReportTableCell>None</ReportTableCell>
                <ReportTableCell>None </ReportTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Container>
  );
};

export default InterpersonalCommunicationsReport;
