
import React from 'react';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import ReportTitle from '../../@lib/components/@common/ReportTitle';
import DateRangePicker from '../../@lib/components/@common/DateRangePicker';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useState } from 'react';
import { useDateRange } from '../../@lib/components/@common/DateRangePicker';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import useSocialNetworksReport from '../../@lib/hooks/useSocialNetworksReport';
import { exportSocialNetworksReport } from '../../@lib/services/reports';

const SocialNetworksReport:React.FC = () => {
  const { t } = useTranslation(['reports', 'common']);
  const notify = useNotify();
  const range = useDateRange();
  const [data, loading] = useSocialNetworksReport(range);
  const [exportingData, setExportingData] = useState(false);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('data_report', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <ReportTitle>{t('social.summary.activities')}</ReportTitle>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          color="secondary"
          disabled={exportingData}
          onClick={async () => {
            try {
              setExportingData(true);
              const [url, filename] = await exportSocialNetworksReport(range.from, range.to);
              const link = document.createElement('a');
              link.setAttribute('href', url);
              link.setAttribute('download', filename);
              link.click();
            }
            catch (err) {
              notify(err as Error);
            }
            finally {
              setExportingData(false);
            }
          }}
        >{t('export', { ns: 'common' })}</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#B072CD' }}>
              <ReportTableCell width={200} sx={{ color: '#ffffff!important' }}>{t('platform')}</ReportTableCell>
              <ReportTableCell sx={{ color: '#ffffff!important' }}>{t('designation')}</ReportTableCell>
              <ReportTableCell colSpan={6} sx={{ color: '#ffffff!important' }}>{t('women')}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <ReportTableCell />
              <ReportTableCell />
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('18_24_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_34_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('35_44_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('45_54_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('55_64_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('65_plus')}</ReportTableCell>
            </TableRow>
            {dataLoaded && data[0].female.map((network, i) => (
              <React.Fragment key={`${i}`}>
                {network.data.map((_, j) => (
                  <TableRow hover key={`${i}-${j}`}>
                    {j === 0 && (
                      <ReportTableCell sx={{ verticalAlign: 'top' }} rowSpan={network.data.length}>{network.network_name}</ReportTableCell>
                    )}
                    <ReportTableCell>{_.designation}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_18_24}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_25_34}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_35_44}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_45_54}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_55_64}</ReportTableCell>
                    <ReportTableCell>{_.data[0].female_65_plus}</ReportTableCell>
                  </TableRow>
                ))}
                <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                  <ReportTableCell size="medium">{t('total', { ns: 'common' })}</ReportTableCell>
                  <ReportTableCell />
                  <ReportTableCell>{network.total[0].total_female_18_24}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_female_25_34}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_female_35_44}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_female_45_54}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_female_55_64}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_female_65_plus}</ReportTableCell>
                </TableRow>
              </React.Fragment>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={8} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#515B9D' }}>
              <ReportTableCell width={200} sx={{ color: '#ffffff!important' }}>{t('platform')}</ReportTableCell>
              <ReportTableCell sx={{ color: '#ffffff!important' }}>{t('designation')}</ReportTableCell>
              <ReportTableCell colSpan={6} sx={{ color: '#ffffff!important' }}>{t('men')}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <ReportTableCell />
              <ReportTableCell />
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('18_24_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_34_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('35_44_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('45_54_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('55_64_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('65_plus')}</ReportTableCell>
            </TableRow>
            {dataLoaded && data[0].male.map((network, i) => (
              <React.Fragment key={`${i}`}>
                {network.data.map((_, j) => (
                  <TableRow hover key={`${i}-${j}`}>
                    {j === 0 && (
                      <ReportTableCell sx={{ verticalAlign: 'top' }} rowSpan={network.data.length}>{network.network_name}</ReportTableCell>
                    )}
                    <ReportTableCell>{_.designation}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_18_24}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_25_34}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_35_44}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_45_54}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_55_64}</ReportTableCell>
                    <ReportTableCell>{_.data[0].male_65_plus}</ReportTableCell>
                  </TableRow>
                ))}
                <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                  <ReportTableCell size="medium">{t('total', { ns: 'common' })}</ReportTableCell>
                  <ReportTableCell />
                  <ReportTableCell>{network.total[0].total_male_18_24}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_male_25_34}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_male_35_44}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_male_45_54}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_male_55_64}</ReportTableCell>
                  <ReportTableCell>{network.total[0].total_male_65_plus}</ReportTableCell>
                </TableRow>
              </React.Fragment>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={8} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default SocialNetworksReport;
