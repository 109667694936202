
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import frFlag from '../../images/flags/france.png';
// import usFlag from '../../images/flags/usa.png';
import enFlag from '../../images/flags/united-kingdom.png';

import { useState, useLayoutEffect, useCallback } from 'react';
import i18n from '../../../i18n';

export type LanguageOptions = 'fr'|'en';
export type LanguageSwitchProps = ButtonBaseProps & {
  defaultValue?: LanguageOptions;
  onChange?: (lng:string)=>void;
  persistent?: boolean;
  storageKey?: string;
  dropDownArrowPosition?: 'left'|'right'|'none';
};

function getLanguageFlagImage (lang:LanguageOptions) {
  switch (lang) {
    case 'en':
      return enFlag;
    case 'fr':
    default:
      return frFlag;
  }
}

const LanguageSwitch:React.FC<LanguageSwitchProps> = (props) => {
  const {
    persistent,
    storageKey='lang',
    defaultValue='fr',
    onChange,
    dropDownArrowPosition='right',
    children,
    ...buttonBaseProps
  } = props;

  const [language, setLanguage] = useState<LanguageOptions>(persistent ? window.localStorage.getItem(storageKey) as LanguageOptions || defaultValue : defaultValue);
  const [localeSelectorEl, setLocaleSelectorEl] = useState<HTMLElement|null>(null);

  const handleChange = useCallback((lng:LanguageOptions) => {
    persistent && window.localStorage.setItem(storageKey, lng);
    onChange && onChange(lng);
    setLanguage(lng);
  }, [onChange, persistent, storageKey]);

  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [language])

  return (
    <>
      <ButtonBase {...buttonBaseProps} onClick={(ev)=>setLocaleSelectorEl(ev.currentTarget)}>
        {dropDownArrowPosition === 'left' && <DropDownIcon color="action" />}
        <Avatar src={getLanguageFlagImage(language)} sx={{ width: 24, height: 24 }} />
        {dropDownArrowPosition === 'right' && <DropDownIcon color="action" />}
      </ButtonBase>
      <Menu
        anchorEl={localeSelectorEl}
        open={!!localeSelectorEl}
        onClose={()=>setLocaleSelectorEl(null)}
        onClick={()=>setLocaleSelectorEl(null)}
      >
        <MenuItem onClick={() => handleChange('fr')} selected={language==='fr'}>
          <Avatar src={frFlag} sx={{ height: 32, width: 32, mr: 2 }} /> Français
        </MenuItem>
        <MenuItem onClick={() => handleChange('en')} selected={language==='en'}>
          <Avatar src={enFlag} sx={{ height: 32, width: 32, mr: 2 }} /> English
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSwitch;
