
import React from 'react';
import { UserPasswordData } from '@ademas/client';
import Stack from '@mui/material/Stack';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormLabel from '@mui/material/FormLabel';
import PasswordTextField from '../@common/PasswordTextField';
import Button from '@mui/material/Button';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type PasswordChangeDialogProps = DialogProps & {
  title?: string;
  actionButtonText?: string;
  disabled?: boolean;
  onSave?: (credentials:UserPasswordData) => void;
  onCancel?: () => void;
};

const PasswordChangeDialog:React.FC<PasswordChangeDialogProps> = (props) => {
  const {
    title="Change password",
    actionButtonText="Confirm",
    onSave,
    onCancel=()=>void 0,
    disabled,
    children,
    ...dialogProps
  } = props;

  const { t } = useTranslation('common');

  const handleSave:React.FormEventHandler<HTMLFormElement> = useCallback((ev) => {
    ev.preventDefault();
    if (!onSave)
      return;

    const formData = new FormData(ev.currentTarget);
    const creds = Object.fromEntries(formData) as UserPasswordData;
    onSave(creds);
  }, [onSave]);

  return (
    <Dialog
      disableEscapeKeyDown
      {...dialogProps}
      PaperProps={{
        component: 'form',
        onSubmit: handleSave
      }}
    >
      <DialogTitle color="primary">{title}</DialogTitle>
      <DialogContent >
        <Stack spacing={2} py={2}>
          <div>
            <FormLabel>{t('password.new.placeholder')}</FormLabel>
            <PasswordTextField
              name="password"
              placeholder={t('password.new')}
              required
              fullWidth
              disabled={disabled}
            />
          </div>
          <div>
            <FormLabel>{t('password.confirm')}</FormLabel>
            <PasswordTextField
              name="password_confirmation"
              placeholder={t('password.new.repeat')}
              required
              fullWidth
              disabled={disabled}
            />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={onCancel}
          disabled={disabled}
        >{t('cancel')}</Button>

        <Button
          type="submit"
          disabled={disabled}
        >{actionButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeDialog;
