
import React from 'react';

import { useState, useRef, useEffect } from 'react';
import context from './context';

export type GoogleMapProps = google.maps.MapOptions & {
  containerProps?: React.HTMLProps<HTMLDivElement>;
  children?: React.ReactNode;
};

const GoogleMap:React.FC<GoogleMapProps> = (props) => {
  const {
    mapId='map',
    containerProps,
    children,
    ...mapOptions
  } = props;

  const mapRef = useRef<HTMLDivElement|null>(null);
  const [map, setMap] = useState<google.maps.Map|null>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds|null>(null);

  useEffect(() => {
    if (!map && mapRef.current) {
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
      setBounds(new window.google.maps.LatLngBounds());
    }
    else if (map) {
      map.setOptions(mapOptions);
    }
  }, [map, mapOptions]);

  return (
    <context.Provider value={{map, bounds}}>
      <div {...containerProps} ref={mapRef} id={mapId as string} />
      {children}
    </context.Provider>
  );
};

export default GoogleMap;
