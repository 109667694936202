
import apiClient from '../api-client';
import { ResponseData, SiteVisitActivityReport } from '@ademas/client';
import parseFileResponse from '../utils/parse-file-response';

const getSiteVisitActivityReport = async (from:Date, to:Date) => {
  const res = await apiClient.get<ResponseData<SiteVisitActivityReport>>(`/api/v1/medical_visitor_submissions?start_date=${from.toISOString()}&end_date=${to.toISOString()}`);
  return res.data.data;
};

export const exportSiteVisitActivityReport = async (from:Date, to:Date) => {
  const res = await apiClient.get(`/api/v1/medical_visitor_submissions/export?start_date=${from.toISOString()}&end_date=${to.toISOString()}`, {
    responseType: 'blob'
  });
  return parseFileResponse(res, 'report');
};

export default getSiteVisitActivityReport;
