
import React from 'react';
import MainToolbar from '../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../@lib/components/@common/TableRowSkeleton';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DateRangePicker from '../@lib/components/@common/DateRangePicker';

import { useTranslation } from 'react-i18next';
import { useDateRange } from '../@lib/components/@common/DateRangePicker';
import usePromoterOverviewReport from '../@lib/hooks/usePromoterOverviewReport';

const PromoterDashboard:React.FC = () => {
  const { t } = useTranslation(['dashboard', 'common']);
  const range = useDateRange();
  const [data, loading] = usePromoterOverviewReport(range);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('dashboard', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('point_of_sale_visits')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('type', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.promoter_visits.map((_, i) => (
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.name || '--'}</TableCell>
                      <TableCell>{_.total || '--'}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('products_sold')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('product', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('qty.short', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.promoter_sales.map((_, i) =>(
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.product_name}</TableCell>
                      <TableCell>{_.quantity_sold}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PromoterDashboard;
