
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useEffect } from 'react';
import { useMarker } from '../Marker';

export type InfoWindowProps = {
  children?: React.ReactNode;
};

const InfoWindow:React.FC<InfoWindowProps> = (props) => {
  const { children } = props;
  const marker = useMarker();

  useEffect(() => {
    if (marker && children) {
      const infoWindow = new google.maps.InfoWindow({
        content: renderToStaticMarkup(<>{children}</>)
      });

      marker.addListener('click', () => infoWindow.open({
        anchor: marker
      }));

      marker.getMap()?.addListener('click', () => infoWindow.close());
    }
  }, [marker, children]);

  return null;
};

export default InfoWindow;
