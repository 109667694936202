
import { useState, useLayoutEffect } from 'react';
import { getSocialNetworksReport } from '../services/reports';
import { SocialNetworksReport } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export type SocialNetworksReportData = SocialNetworksReport;

export type DateRange = {
  from:Date;
  to:Date;
};

export interface SocialNetworksReportHook {
  (dateRange:DateRange):[(SocialNetworksReportData|null), boolean]
}

const useSocialNetworksReport:SocialNetworksReportHook = (dateRange) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<SocialNetworksReportData|null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rawData = await getSocialNetworksReport(dateRange.from, dateRange.to);
        setReportData(rawData);
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [dateRange, notify]);

  return [reportData, loading];
};

export default useSocialNetworksReport;
