
import React from 'react';
import MainToolbar from '../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../@lib/components/@common/TableRowSkeleton';
import TableCell from '@mui/material/TableCell';
import ReportTableCell from '../@lib/components/@common/ReportTableCell';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DateRangePicker from '../@lib/components/@common/DateRangePicker';
import Stat, { StatNumber, StatChange, StatHelpText } from '../@lib/components/@common/Stat';

import { useTranslation } from 'react-i18next';
import { useDateRange } from '../@lib/components/@common/DateRangePicker';
import useOverviewReport from '../@lib/hooks/useOverviewReport';

const AdminDashboard:React.FC = () => {
  const { t } = useTranslation(['dashboard', 'common']);
  const range = useDateRange();
  const [data, loading] = useOverviewReport(range);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('dashboard', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>
      {dataLoaded && (
        <Grid container spacing={3}>
          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}
              
            >{t('point_of_sale_visits')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.point_of_sales_visits.number_of_visits}</StatNumber>
              <StatChange type="increase">{(data.point_of_sales_visits.number_of_visits/data.point_of_sales_visits.target_value*100).toFixed(0)}%</StatChange>
              <StatHelpText>{data.point_of_sales_visits.total_visits} / {data.point_of_sales_visits.target_value}</StatHelpText>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('new_point_of_sale')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.new_point_of_sale.number_of_new_point}</StatNumber>
              <StatChange type="increase">{(data.new_point_of_sale.number_of_new_point/data.new_point_of_sale.target_value*100).toFixed(0)}%</StatChange>
              <StatHelpText>{data.new_point_of_sale.total_new_point} / {data.new_point_of_sale.target_value}</StatHelpText>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('global_revenue')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber sx={{ fontSize: '2.5rem' }}>{data.global_revenue.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('talking_sessions')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.talking_sessions.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('home_visits')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.home_visits.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('health_point_visits')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.health_service_point_visits.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('social_publishing')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.social_network_publishing.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>

          <Grid item container direction="column" xs={6} sm={4} md={3} xl={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              mb={1}

            >{t('training_sessions')}</Typography>
            <Stat component={Paper} sx={{ px: 2, py: 4 }}>
              <StatNumber>{data.training_sessions.toLocaleString()}</StatNumber>
            </Stat>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('point_of_sale_visits')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('type', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.pos_list.map((_, i) => (
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.name || '--'}</TableCell>
                      <TableCell>{_.total || '--'}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('health_srvc_point_visits')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('type', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.health_services_list.map((_, i) => (
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.name || '--'}</TableCell>
                      <TableCell>{_.total}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('interpersonal_comm.label')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('type', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.type_activity_list.map((_, i) => (
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.name || '--'}</TableCell>
                      <TableCell>{_.total || '--'}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Paper sx={{ px: 1, pb: 2, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('promotional_items.label')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('name', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.promotional_tools_list.map((_, i) => (
                    <TableRow key={`${i}`} hover>
                      <TableCell>{_.name || '--'}</TableCell>
                      <TableCell>{_.total || '--'}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={2} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Paper sx={{ px: 1, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('products_sold')}</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('name', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('modern_trade')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('traditional_trade')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('pharmaceutique')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{`${t('total', { ns: 'common' })} ${t('revenue', { ns: 'common' })}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.products_list.map((_, i) =>(
                    <TableRow key={`${i}`} hover>
                      <ReportTableCell>{_.product_name}</ReportTableCell>
                      <ReportTableCell>{_.data[0].qty_mt}</ReportTableCell>
                      <ReportTableCell>{_.data[0].qty_tt}</ReportTableCell>
                      <ReportTableCell>{_.data[0].qty_ph}</ReportTableCell>
                      <ReportTableCell>{_.data[0].qty_total}</ReportTableCell>
                      <ReportTableCell>{_.data[0].total_revenue}</ReportTableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ px: 1, minHeight: '100%' }}>
            <Typography variant="h6" color="primary" px={2} py={1.5}>{t('products_revenue')} (%)</Typography>
            <Divider light sx={{ mx: 2, mb: 1 }} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#868686' }}>{t('name', { ns: 'common' })}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('modern_trade')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('traditional_trade')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('pharmaceutique')}</TableCell>
                    <TableCell sx={{ color: '#868686' }}>{t('total', { ns: 'common' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoaded && data.product_revenues_list.map((_, i) =>
                    'product_name' in _ ? (
                      <TableRow key={`${i}`} hover>
                        <ReportTableCell>{_.product_name}</ReportTableCell>
                        <ReportTableCell>{_.data[0].rev_mt}</ReportTableCell>
                        <ReportTableCell>{_.data[0].rev_tt}</ReportTableCell>
                        <ReportTableCell>{_.data[0].rev_ph}</ReportTableCell>
                        <ReportTableCell>{_.data[0].rev_total}</ReportTableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                        <ReportTableCell size="medium">{_.name}%</ReportTableCell>
                        <ReportTableCell>{_.data[0].total_mt}%</ReportTableCell>
                        <ReportTableCell>{_.data[0].total_tt}%</ReportTableCell>
                        <ReportTableCell>{_.data[0].total_ph}%</ReportTableCell>
                        <ReportTableCell>{_.data[0].global_total}%</ReportTableCell>
                      </TableRow>
                    )
                  )}
                  {loading && <TableRowSkeleton rowsCount={3} cellsCount={5} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
