
import apiClient from '../api-client';
import { dropAuthState } from './store';

const signoutUser = async (force=false) => {
  if (!force)
    await apiClient.delete(`/api/v1/users/sign_out`);
  
  await dropAuthState();
  apiClient.defaults.headers.common.Authorization = undefined;
};

export default signoutUser;
