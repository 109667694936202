
import React from 'react';
import { Marker, MarkerProps, InfoWindow } from '../@lib/components/@common/GoogleMap';
import { PointOfSale } from '@ademas/client';
import { useTranslation } from 'react-i18next';

export type PointOfSaleMarkerProps = MarkerProps & {
  pointOfSale: PointOfSale;
};

const POS_MARKER_ICON = {
  path: 'M 0, 5 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0 Z',
  fillColor: '#1CCFF6',
  fillOpacity: 1,
  strokeOpacity: 0
};

const PointOfSaleMarker:React.FC<PointOfSaleMarkerProps> = (props) => {
  const {
    pointOfSale:pos,
    children,
    ...markerProps
  } = props;

  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <Marker
      position={pos.pos_location}
      icon={POS_MARKER_ICON}
      {...markerProps}
    >
      <InfoWindow>
        <div style={{ minWidth: 250, maxWidth: 300, borderRadius: 8, overflow: 'auto' }}>
          <div
            style={{
              backgroundColor: '#1CCFF6',
              color: '#ffffff',
              fontSize: 18,
              fontWeight: 400,
              padding: 16
            }}
          >{t('pos')}</div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('name', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ pos.pos_name || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('type', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ pos.pos_type || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14, borderBottom: '2px solid #F4F2F2' }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('department', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ pos.pos_department || '--' }</p>
          </div>

          <div style={{ paddingInline: 16, paddingBlock: 14 }}>
            <h5 style={{ fontSize: 14, color: '#868686', fontWeight: 400, margin: 0, marginBottom: 4 }}>{t('address', { ns: 'common' })}:</h5>
            <p style={{ fontSize: 18, color: '#565656', margin: 0, fontWeight: 500 }}>{ pos.pos_address || '--' }</p>
          </div>

        </div>
      </InfoWindow>
    </Marker>
  );
};

export default PointOfSaleMarker;
