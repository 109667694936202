
import React, { PropsWithChildren } from 'react';
import { Portal } from '@mui/base/Portal';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { useToolbar } from '../MainLayout';

export type MainToolbarProps = {
  title?: string;
  titleTypographyProps?: TypographyProps;
};

const MainToolbar:React.FC<PropsWithChildren<MainToolbarProps>> = (props) => {
  const {
    title,
    titleTypographyProps,
    children
  } = props;

  const toolbarEle = useToolbar();

  return (
    <Portal container={toolbarEle}>
      {title && (
        <Typography color="primary" variant="h6" {...titleTypographyProps}>{title}</Typography>
      )}
      {children}
    </Portal>
  );
};

export default MainToolbar;
