
import React from 'react';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import ReportTitle from '../../@lib/components/@common/ReportTitle';
import DateRangePicker from '../../@lib/components/@common/DateRangePicker';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';

import { useState } from 'react';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useDateRange } from '../../@lib/components/@common/DateRangePicker';
import { useTranslation } from 'react-i18next';
import useSiteVisitActivityReport from '../../@lib/hooks/useSiteVisitActivityReport';
import { exportSiteVisitActivityReport } from '../../@lib/services/reports';

const HealthServicePointsReport:React.FC = () => {
  const { t } = useTranslation(['reports', 'common']);
  const notify = useNotify();
  const range = useDateRange();
  const [data, loading] = useSiteVisitActivityReport(range);
  const [exportingData, setExportingData] = useState(false);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('data_report', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <ReportTitle>{t('health_points.points_visited')}</ReportTitle>
        <Button
          component={Link}
          download
          variant="contained"
          startIcon={<DownloadIcon />}
          color="secondary"
          disabled={exportingData}
          onClick={async () => {
            try {
              setExportingData(true);
              const [url, filename] = await exportSiteVisitActivityReport(range.from, range.to);
              const link = document.createElement('a');
              link.setAttribute('href', url);
              link.setAttribute('download', filename);
              link.click();
            }
            catch (err) {
              notify(err as Error);
            }
            finally {
              setExportingData(false);
            }
          }}
        >{t('export', { ns: 'common' })}</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            {dataLoaded && (
              <TableRow>
                <ReportTableCell width={200}>{t('health_points.point_type')}</ReportTableCell>
                {data.type_structures[0].data.map((stat, i) => (
                  <ReportTableCell key={`${stat.region}-${i}`} sx={{ textTransform: 'capitalize' }}>{'region' in stat ? stat.region : t('total', { ns: 'common' })}</ReportTableCell>
                ))}
              </TableRow>
            )}

            {loading && <TableRowSkeleton rowsCount={1} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableHead>
          <TableBody>
            {dataLoaded && data.type_structures.map((_, i) => (
              <TableRow key={`${_.name}-${i}`} hover={i+1 < data.type_structures.length} sx={{ backgroundColor: i+1 === data.type_structures.length ? '#fbfbfb' : 'unset' }}>
                <ReportTableCell size={i+1 === data.type_structures.length ? 'medium' : 'small'}>{_.name}</ReportTableCell>
                {_.data.map((stat, i) => (
                  <ReportTableCell
                    key={`${stat.region}-${i}`}
                  >{'count' in stat ? stat.count : stat.total}</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>{t('health_points.providers_visited')}</ReportTitle>
        {/* <Button variant="contained" startIcon={<DownloadIcon />} color="secondary">Export</Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            {dataLoaded && (
              <TableRow>
                <ReportTableCell width={200}>{t('health_points.contact_person')}</ReportTableCell>
                {data.type_structures[0].data.map((stat, i) => (
                  <ReportTableCell key={`${stat.region}-${i}`} sx={{ textTransform: 'capitalize' }}>{'region' in stat ? stat.region : t('total', { ns: 'common' })}</ReportTableCell>
                ))}
              </TableRow>
            )}

            {loading && <TableRowSkeleton rowsCount={1} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableHead>
          <TableBody>
            {dataLoaded && data.type_fonctions.map((_, i) => (
              <TableRow key={`${_.name}-${i}`} hover={i+1 < data.type_fonctions.length} sx={{ backgroundColor: i+1 === data.type_fonctions.length ? '#fbfbfb' : 'unset' }}>
                <ReportTableCell size={i+1 === data.type_fonctions.length ? 'medium' : 'small'}>{_.name}</ReportTableCell>
                {_.data.map((stat, i) => (
                  <ReportTableCell
                    key={`${stat.region}-${i}`}
                  >{'count' in stat ? stat.count : stat.total}</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>{t('health_points.contacts_by_gender')}</ReportTitle>
        {/* <Button variant="contained" startIcon={<DownloadIcon />} color="secondary">Export</Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('region', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell colSpan={3}>{t('sector.private')}</ReportTableCell>
              <ReportTableCell colSpan={3}>{t('sector.public')}</ReportTableCell>
              <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <ReportTableCell />
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('women')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('men')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('set')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('women')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('men')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('set')}</ReportTableCell>
              <ReportTableCell/>
            </TableRow>
            {dataLoaded && data.type_secteurs.map((_, i) => i+1 < data.type_secteurs.length ? (
              <TableRow key={`${i}`} hover>
                <ReportTableCell sx={{ textTransform: 'capitalize' }}>{_.name}</ReportTableCell>
                <ReportTableCell>{_.data[0]?.data?.at(0)?.female}</ReportTableCell>
                <ReportTableCell>{_.data[0]?.data?.at(0)?.male}</ReportTableCell>
                <ReportTableCell>{_.data[0]?.data?.at(0)?.total}</ReportTableCell>
                <ReportTableCell>{_.data[1]?.data?.at(0)?.female}</ReportTableCell>
                <ReportTableCell>{_.data[1]?.data?.at(0)?.male}</ReportTableCell>
                <ReportTableCell>{_.data[1]?.data?.at(0)?.total}</ReportTableCell>
                <ReportTableCell>{_.data[2]?.total_region}</ReportTableCell>
              </TableRow>
            ):(
              <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{_.name}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_private_female}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_private_male}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_private}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_public_female}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_public_male}</ReportTableCell>
                <ReportTableCell>{_.data[0].total_public}</ReportTableCell>
                <ReportTableCell/>
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={8} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>{t('health_points.breakdown.msr')}</ReportTitle>
        {/* <Button variant="contained" startIcon={<DownloadIcon />} color="secondary">Export</Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('region', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('vm')}</ReportTableCell>
              {dataLoaded && data.medical_visitors.dakar[0]?.data.map((_, i) => (
                <ReportTableCell key={`${i}`}>{_.fonction || t('total', { ns: 'common' })}</ReportTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoaded && data.medical_visitors.dakar.map((_, i) => i+1 < data.medical_visitors.dakar.length ? (
              <TableRow key={`${i}`} hover>
                <ReportTableCell sx={{ textTransform: 'capitalize' }}>{_.region}</ReportTableCell>
                <ReportTableCell>{_.vm}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'count' in _ ? _.count : _.total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ):(
              <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('dakar')}</ReportTableCell>
                <ReportTableCell>{_.name}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'total' in _ ? _.total : _.global_total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {dataLoaded && data.medical_visitors.others_region.map((_, i) => i+1 < data.medical_visitors.others_region.length ? (
              <TableRow key={`${i}`} hover>
                <ReportTableCell sx={{ textTransform: 'capitalize' }}>{_.region}</ReportTableCell>
                <ReportTableCell>{_.vm}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'count' in _ ? _.count : _.total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ):(
              <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('region', { ns: 'common' })}</ReportTableCell>
                <ReportTableCell>{_.name}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'total' in _ ? _.total : _.global_total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>{t('health_points.breakdown.struct_types')}</ReportTitle>
        {/* <Button variant="contained" startIcon={<DownloadIcon />} color="secondary">Export</Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('region', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('vm')}</ReportTableCell>
              {dataLoaded && data.medical_visitors_per_type_structure.dakar[0]?.data.map((_, i) => (
                <ReportTableCell key={`${i}`}>{_.type_structure || t('total', { ns: 'common' })}</ReportTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoaded && data.medical_visitors_per_type_structure.dakar.map((_, i) => i+1 < data.medical_visitors_per_type_structure.dakar.length ? (
              <TableRow key={`${i}`} hover>
                <ReportTableCell sx={{ textTransform: 'capitalize' }}>{_.region}</ReportTableCell>
                <ReportTableCell>{_.vm}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'count' in _ ? _.count : _.total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ):(
              <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('dakar')}</ReportTableCell>
                <ReportTableCell>{_.name}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'total' in _ ? _.total : _.global_total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {dataLoaded && data.medical_visitors_per_type_structure.others_region.map((_, i) => i+1 < data.medical_visitors_per_type_structure.others_region.length ? (
              <TableRow key={`${i}`} hover>
                <ReportTableCell sx={{ textTransform: 'capitalize' }}>{_.region}</ReportTableCell>
                <ReportTableCell>{_.vm}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'count' in _ ? _.count : _.total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ):(
              <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('region', { ns: 'common' })}</ReportTableCell>
                <ReportTableCell>{_.name}</ReportTableCell>
                {_.data.map((_, j) => (
                  <ReportTableCell key={`${i}-${j}`}>{'total' in _ ? _.total : _.global_total || 0 }</ReportTableCell>
                ))}
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default HealthServicePointsReport;
