
import React from 'react';
import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';

import { styled } from '@mui/material/styles';

const Chip = styled(MuiChip)(({ theme, color }) => ({
  backgroundColor: '#ffffff',
  boxShadow: 'rgba(0,0,0,0.1) 0 0 2px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  '&.MuiChip-outlined': {
    backgroundColor: '#F1F6FF',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#F1F6FF'
    }
  }
}))

export type ChoiceChipsOption<T> = {
  label?: React.ReactNode;
  value: T;
};

export type ChoiceChipsProps<T = any> = {
  options: ChoiceChipsOption<T>[];
  value?: T;
  onChange: (newValue:T)=>void;
};

const ChoiceChips:React.FC<ChoiceChipsProps> = (props) => {
  const {
    options,
    value,
    onChange
  } = props;

  return (
    <Stack direction="row" spacing={1}>
      {options.map((opt, i) => (
        <Chip
          key={`${i}`}
          label={opt.label || opt.value}
          onClick={()=>onChange(opt.value)}
          variant={value === opt.value ? 'outlined' : 'filled'}
        />
      ))}
    </Stack>
  );
};

export default ChoiceChips;
