
import React from 'react';
import { UserProfileData, UserPasswordData } from '@ademas/client';
import Grid from '@mui/material/Grid';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import NativeSelect from '@mui/material/NativeSelect';
import PasswordTextField from '../@common/PasswordTextField';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type EditUserDialogProps = DialogProps & {
  initialData?: Partial<UserProfileData>;
  title?: string;
  actionButtonText?: string;
  editablePassword?: boolean;
  editableRole?: boolean;
  disabled?: boolean;
  onSave?: (user:UserProfileData & UserPasswordData) => void;
  onClose?: () => void;
};

const ROLE_OPTIONS = [
  { label: 'Promoter', value: 'promoter' },
  { label: 'Medical Visitor', value: 'medical_visitor' },
  { label: 'Internal Agent', value: 'internal_agent' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Manager', value: 'manager' },
  { label: 'Admin', value: 'admin' }
];

const EditUserDialog:React.FC<EditUserDialogProps> = (props) => {
  const {
    initialData={},
    title="Edit User",
    actionButtonText="Save",
    editablePassword,
    editableRole,
    onSave,
    disabled,
    children,
    ...dialogProps
  } = props;

  const { t } = useTranslation('common');

  const handleSave:React.FormEventHandler<HTMLFormElement> = useCallback((ev) => {
    ev.preventDefault();
    if (!onSave)
      return;

    const formData = new FormData(ev.currentTarget);
    const user = Object.fromEntries(formData) as UserProfileData;
    onSave(user);
  }, [onSave]);

  return (
    <Dialog
      disableEscapeKeyDown
      {...dialogProps}
      PaperProps={{
        component: 'form',
        onSubmit: handleSave
      }}
    >
      <DialogTitle color="primary">{title}</DialogTitle>
      <DialogContent >
        <Grid container spacing={2} py={2}>
          <Grid item xs={12} sm={6}>
            <FormLabel>{t('first_name')}</FormLabel>
            <OutlinedInput
              name="firstname"
              defaultValue={initialData.firstname}
              placeholder={t('first_name.placeholder')}
              required
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>{t('last_name')}</FormLabel>
            <OutlinedInput
              name="lastname"
              defaultValue={initialData.lastname}
              placeholder={t('last_name.placeholder')}
              required
              fullWidth
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel>{t('username')}</FormLabel>
            <OutlinedInput
              name="username"
              defaultValue={initialData.username}
              placeholder={t('username.placeholder')}
              required
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>{t('email.short')}</FormLabel>
            <OutlinedInput
              name="email"
              defaultValue={initialData.email}
              type="email"
              placeholder={t('email.placeholder')}
              required
              fullWidth
              disabled={disabled}
            />
          </Grid>

          {editablePassword && (
            <>
              <Grid item xs={12} sm={6}>
                <FormLabel>{t('password')}</FormLabel>
                <PasswordTextField
                  name="password"
                  placeholder={t('password.placeholder')}
                  required
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel>{t('password.confirm')}</FormLabel>
                <PasswordTextField
                  name="password_confirmation"
                  placeholder={t('password.repeat')}
                  required
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          
          <Grid item xs={12} sm={6}>
            <FormLabel>{t('function')}</FormLabel>
            <OutlinedInput
              name="function"
              defaultValue={initialData.function}
              placeholder={t('function.placeholder')}
              fullWidth
              disabled={disabled}
            />
          </Grid>

          {editableRole && (
            <Grid item xs={12} sm={6}>
              <FormLabel>{t('role')}</FormLabel>
              <NativeSelect
                name="role"
                input={<OutlinedInput />}
                IconComponent={ExpandMoreIcon}
                required
                fullWidth
                defaultValue={initialData.role}
                disabled={disabled}
              >
                <option value="">{t('none')}</option>
                {ROLE_OPTIONS.map((opt, i) => (
                  <option key={`${opt.value}-${i}`} value={opt.value}>{opt.label}</option>
                ))}
              </NativeSelect>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={dialogProps.onClose}
          disabled={disabled}
        >{t('cancel')}</Button>

        <Button
          type="submit"
          disabled={disabled}
        >{actionButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
