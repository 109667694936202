
import { createContext } from 'react';

export type DateRangeContext = {
  from: Date;
  to: Date;
  update: (from?: Date, to?: Date)=>void;
}

const context = createContext<DateRangeContext>({
  from: new Date(0),
  to: new Date(),
  update () {}
});

export default context;
