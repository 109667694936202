
import apiClient from '../api-client';
import { UserProfile, ResponseData } from '@ademas/client';
import { setAccessToken, setRefreshToken, setAuthUser } from './store';

export type AuthCredentials = {
  username: string;
  password: string;
};

const signinUser = async (creds: AuthCredentials) => {
  const res = await apiClient.post<ResponseData<UserProfile>>(`/api/v1/users/sign_in`, creds);
  const headers = res.headers;
  const access_token = headers['access-token'] as string | undefined;
  const refresh_token = headers['refresh-token'] as string | undefined;
  const user = res.data.data;

  if (!access_token || !refresh_token)
    throw new Error('Incomplete response from the server.');

  await Promise.all([
    setAccessToken(access_token),
    setRefreshToken(refresh_token),
    setAuthUser(user)
  ]);

  apiClient.defaults.headers.common.Authorization = access_token;
  return user;
};

export default signinUser;
