
import { useLayoutEffect, useState } from 'react';
import { listUsers } from '../services/users';
import { UserProfile } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export interface UsersListHook {
  ():[UserProfile[], boolean]
}

const useUsersList:UsersListHook = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserProfile[]>([]);

  useLayoutEffect(() => {
    (async ()=>{
      try {
        setLoading(true);
        const users = await listUsers();
        setUsers(users);
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [notify]);

  return [users, loading];
};

export default useUsersList;
