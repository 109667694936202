
import React from 'react';
import { UserProfileData, UserPasswordData } from '@ademas/client';
import MainToolbar from '../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import EditUserDialog from '../@lib/components/EditUserDialog';
import PasswordChangeDialog from '../@lib/components/PasswordChangeDialog';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthSession } from '../@lib/components/AuthSessionProvider';
import { useNotify } from '../@lib/components/NotificationsProvider';
import { styled } from '@mui/material/styles';
import { updateUserProfile, updatePassword } from '../@lib/services/auth';

const OutlinedInput = styled(MuiOutlinedInput)(({theme}) => ({
  '& .MuiOutlinedInput-input.Mui-disabled': {
    textFillColor: theme.palette.text.primary
  }
}));

const Account:React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  const notify = useNotify();
  const { user:currUser } = useAuthSession();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [updatingInfo, setUpdatingInfo] = useState(false);

  const handleUserInfoUpdate = useCallback(async (data:UserProfileData) => {
    try {
      setUpdatingInfo(true);
      await updateUserProfile(data);
      notify(t('account.msg.profile_updated'), { variant: 'success' });
      setEditUserDialogOpen(false);
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally {
      setUpdatingInfo(false);
    }
  }, [t, notify]);

  const handlePasswordUpdate = useCallback(async (creds:UserPasswordData) => {
    try {
      setUpdatingInfo(true);
      await updatePassword(creds);
      notify(t('account.msg.password_updated'), { variant: 'success' });
      setPasswordDialogOpen(false);
    }
    catch(err) {
      notify(err as Error);
    }
    finally {
      setUpdatingInfo(false);
    }
  }, [t, notify]);

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('my_account', { ns: 'common' })} />
      <EditUserDialog
        title={t('account.info.edit')}
        initialData={currUser || undefined}
        open={editUserDialogOpen}
        actionButtonText={t('save', { ns: 'common' })}
        onSave={handleUserInfoUpdate}
        onClose={() => setEditUserDialogOpen(false)}
        disabled={updatingInfo}
        disableEscapeKeyDown
      />
      <PasswordChangeDialog
        open={passwordDialogOpen}
        onSave={handlePasswordUpdate}
        onCancel={() => setPasswordDialogOpen(false)}
        disabled={updatingInfo}
      />

      <Paper sx={{ px: 3, pt: 2, pb: 10 }}>
        <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={400} color="primary">{t('account.info.label')}</Typography>
          <Link variant="h6" color="#6B7CF4" href="#" onClick={()=>setEditUserDialogOpen(true)}>{t('account.info.edit')}</Link>
        </Stack>
        <Divider light />

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('first_name', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.firstname} disabled fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('last_name', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.lastname} disabled fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('username', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.username} disabled fullWidth />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} />

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('email.short', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.email} disabled fullWidth />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('password', { ns: 'common' })}</Typography>
            <OutlinedInput value="********" disabled fullWidth />
            <div style={{ textAlign: 'right', padding: 4 }}>
              <Link href="#" color="#6B7CF4" onClick={() => setPasswordDialogOpen(true)}>{t('password.change', { ns: 'common' })}</Link>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('function', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.function} disabled fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography color="text.secondary" ml={1.5} mb={1}>{t('role', { ns: 'common' })}</Typography>
            <OutlinedInput value={currUser?.role} disabled fullWidth />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Account;