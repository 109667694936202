
import React from 'react';
import MainToolbar from '../@lib/components/MainToolbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ChoiceChips from '../@lib/components/@common/ChoiceChips';
import TableRowSkeleton from '../@lib/components/@common/TableRowSkeleton';
import RegionsMap from '../@lib/components/RegionsMap';
import PointOfSaleMarker from './PointOfSaleMarker';
import MedicalStructureMarker from './MedicalStructureMarker';
import { PointOfSale, MedicalStructure } from '@ademas/client';

import { useState, useLayoutEffect, useMemo } from 'react';
import { useNotify } from '../@lib/components/NotificationsProvider';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { listPointOfSales, listMedicalStructures } from '../@lib/services/sites';

type SitesMapping = {
  [region:string]: {
    pointOfSales: PointOfSale[];
    medicalStructures: MedicalStructure[];
  }
};

const DotSymbol = styled('div')({
  display: 'inline-block',
  lineHeight: 0,
  width: '0.7em',
  height: '0.7em',
  borderRadius: '100%'
});

const SalesSymbol = styled(DotSymbol)({ backgroundColor: '#1CCFF6' });
const HealthServicesSymbol = styled(DotSymbol)({ backgroundColor: '#3DD294' });

const SENEGAL_LOCATION = { lat: 14.43959, lng: -14.53814 };

const Map:React.FC = () => {
  const { t } = useTranslation(['dashboard', 'common']);
  const notify = useNotify();
  const [filter, setFilter] = useState('');
  const [loadingSites, setLoadingSites] = useState(false);
  const [pointOfSales, setPointOfSales] = useState<PointOfSale[]>([]);
  const [medicalStructures, setMedicalStructures] = useState<MedicalStructure[]>([]);

  const filterOptions = useMemo(() => ([
    { label: t('all', { ns: 'common' }), value: '' },
    { label: <><SalesSymbol style={{ marginRight: 4 }} /> {t('sales')}</>, value: 'sales' },
    { label: <><HealthServicesSymbol style={{ marginRight: 4 }} /> {t('health_services')}</>, value: 'health_services' },
  ]), [t]);

  const sitesMapping = useMemo(() => {
    const mapping:SitesMapping = {};

    for (let i=0; i < Math.max(pointOfSales.length, medicalStructures.length); i++) {
      const pos = pointOfSales[i];
      const medStrct = medicalStructures[i];

      const posRegion = (pos?.pos_region || '_').toLowerCase();
      const posRegionMap = mapping[posRegion] || { pointOfSales: [], medicalStructures: [] };
      posRegionMap.pointOfSales.push(pos);
      mapping[posRegion] = posRegionMap;

      const medStrctRegion = (medStrct?.region || '_').toLocaleLowerCase();
      const medStrctRegionMap = mapping[medStrctRegion] || { pointOfSales: [], medicalStructures: [] };
      medStrctRegionMap.medicalStructures.push(medStrct);
      mapping[medStrctRegion] = medStrctRegionMap;
    }

    return mapping;
  }, [pointOfSales, medicalStructures]);

  const regions = useMemo(() => {
    return Object.keys(sitesMapping).sort();
  }, [sitesMapping]);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoadingSites(true);
        const [pointOfSales, medicalStructures] = await Promise.all([
          listPointOfSales(),
          listMedicalStructures()
        ]);
        setPointOfSales(pointOfSales);
        setMedicalStructures(medicalStructures);
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoadingSites(false);
      }
    })();
  }, [notify]);

  return (
    <Container sx={{ pt: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <MainToolbar title={t('map', { ns: 'common' })} />

      <Box mb={2.5}>
        <Typography component="div" variant="caption" mb={0.5}>{t('filter', { ns: 'common' })}</Typography>
        <ChoiceChips
          options={filterOptions}
          value={filter}
          onChange={(v)=>setFilter(v)}
        />
      </Box>

      <Grid container spacing={2} flexGrow={1}>
        <Grid item xs={12} md={3}>
          <TableContainer component={Paper} variant="outlined" sx={{ px: 2, pb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '100%' }}>{t('region', { ns: 'common' })}</TableCell>
                  {(!filter || filter === 'sales') && <TableCell><SalesSymbol /></TableCell>}
                  {(!filter || filter === 'health_services') && <TableCell><HealthServicesSymbol /></TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {loadingSites && <TableRowSkeleton rowsCount={3} cellsCount={3} tableCellProps={{ sx: { py: 0.25 } }} />}
                {regions.map((region, i) => (
                  <TableRow key={`${region}-${i}`} hover>
                    <TableCell sx={{ py: 0.25, textTransform: 'capitalize' }}>{region}</TableCell>
                    {(!filter || filter === 'sales') && <TableCell sx={{ py: 0.25 }}>{sitesMapping[region].pointOfSales.length}</TableCell>}
                    {(!filter || filter === 'health_services') && <TableCell sx={{ py: 0.25 }}>{sitesMapping[region].medicalStructures.length}</TableCell>}
                  </TableRow>
                ))}
                
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={9}>
          <RegionsMap
            center={SENEGAL_LOCATION}
            zoom={8}
          >
            {(!filter || filter === 'sales') && pointOfSales.map((pos, i) => (
              <PointOfSaleMarker
                key={`${pos.pos_name}-${i}`}
                pointOfSale={pos}
              />
            ))}

            {(!filter || filter === 'health_services') && medicalStructures.map((strct, i) => (
              <MedicalStructureMarker
                key={`${strct.nom_prenom}-${i}`}
                medicalStructure={strct}
              />
            ))}
          </RegionsMap>
        </Grid> 
      </Grid>
    </Container>
  );
};

export default Map;
