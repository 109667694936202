
import apiClient from '../api-client';
import { ResponseData, TrainingSessionsReport } from '@ademas/client';
import parseFileResponse from '../utils/parse-file-response';

const getTrainingSessionsReport = async (from:Date, to:Date) => {
  const res = await apiClient.get<ResponseData<TrainingSessionsReport>>(`/api/v1/training_submissions?start_date=${from.toISOString()}&end_date=${to.toISOString()}`);
  return res.data.data;
};

export const exportTrainingSessionsReport = async (from:Date, to:Date) => {
  const res = await apiClient.get(`/api/v1/training_submissions/export?start_date=${from.toISOString()}&end_date=${to.toISOString()}`, {
    responseType: 'blob'
  });

  return parseFileResponse(res, 'report');
};

export default getTrainingSessionsReport;
