
import React from 'react';
import { UserProfileData, UserPasswordData, UserProfile } from '@ademas/client';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditUserDialog from '../../@lib/components/EditUserDialog';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import useUsersList from '../../@lib/hooks/useUsersList';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import { newUser, updateUser, deleteUser } from '../../@lib/services/users';

const Users:React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  const notify = useNotify();
  const [users, loading] = useUsersList();
  const { user:currUser } = useAuthSession();
  const [newUsers, setNewUsers] = useState<UserProfile[]>([]);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserProfile>();
  const [savingUser, setSavingUser] = useState(false);

  const handleAddNewUser = useCallback(async (data:UserProfileData&UserPasswordData) => {
    try {
      setSavingUser(true);
      const user = await newUser(data);
      setNewUsers(curr => [...curr, user]);
      setNewUserDialogOpen(false);
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingUser(false);
    }
  }, [notify]);

  const handleSaveUser = useCallback(async (data:UserProfileData) => {
    try {
      if (!userToEdit)
        return;

      setSavingUser(true);
      await updateUser(userToEdit.id, data);
      //TODO: Reload page or update existing user item
      setUserToEdit(undefined);
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingUser(false);
    }
  }, [userToEdit, notify]);

  const handleDeleteUser = useCallback(async (user:UserProfile) => {
    try {
      setSavingUser(true);
      if(!window.confirm(`${t('msg.delete_user')} "${user.firstname} (${user.username})". ${t('msg.confirm')}`))
        return;
      
      await deleteUser(user.id);
      //TODO: Reload page or update existing user item
      window.location.reload();
    }
    catch(err) {
      notify(err as Error);
    }
    finally{
      setSavingUser(false);
    }
  }, [t, notify]);

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('settings', { ns: 'common' })} />
      {/* BEGIN: New User Dialog */}
      <EditUserDialog
        title={t('users.add_new.label')}
        actionButtonText={t('add', { ns: 'common' })}
        open={newUserDialogOpen}
        onSave={handleAddNewUser}
        onClose={() => setNewUserDialogOpen(false)}
        editablePassword
        editableRole
        disabled={savingUser}
      />
      {/* BEGIN: Edit User Dialog */}
      <EditUserDialog
        title={t('users.edit.label')}
        actionButtonText={t('save', { ns: 'common' })}
        open={!!userToEdit}
        initialData={userToEdit}
        editableRole
        onSave={handleSaveUser}
        onClose={() => setUserToEdit(undefined)}
        disabled={savingUser}
      />

      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Typography variant="h5">{t('users', { ns: 'common' })}</Typography>
        <Button
          color="secondary"
          onClick={() => setNewUserDialogOpen(true)}
          disabled={loading || savingUser}
        >{t('users.add_new.label')}</Button>
      </Stack>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell>{t('first_name', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('last_name', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('username', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('role', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell>{t('actions', { ns: 'common' })}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newUsers.concat(users).map((user, i) => (
              <TableRow key={`${user.id}-${i}`} hover>
                <ReportTableCell>{user.firstname}</ReportTableCell>
                <ReportTableCell>{user.lastname}</ReportTableCell>
                <ReportTableCell>{user.username}</ReportTableCell>
                <ReportTableCell>{user.role}</ReportTableCell>
                <ReportTableCell padding="checkbox">
                  <IconButton disabled={savingUser} size="large" onClick={() => setUserToEdit(user)}><EditIcon color="primary" /></IconButton>
                  {currUser && currUser.id !== user.id && (
                    <IconButton disabled={savingUser} size="large" onClick={() => handleDeleteUser(user)}><DeleteIcon color="error" /></IconButton>
                  )}
                </ReportTableCell>
              </TableRow>
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={4} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Users;
