
import React, { Suspense } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangeProvider } from './@lib/components/@common/DateRangePicker';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import NotificationsProvider from './@lib/components/NotificationsProvider';
import lightTheme from './@lib/themes/light';
import router from './router';
import AuthSessionProvider from './@lib/components/AuthSessionProvider';

const DEFAULT_DATE_RANGE = (()=>{
  let from = new Date();
  let to = new Date();
  // Unset time values
  from = new Date(from.toDateString());
  to = new Date(to.toDateString());
  // Set from date to start of month;
  from.setDate(1);
  // Set time value for "to" date to last millisecond of the day
  to.setDate(to.getDate() + 1);
  to.setMilliseconds(-1);

  return {from, to};
})();

function App() {
  return (
    <Suspense fallback="loading...">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationsProvider maxSnack={2}>
          <DateRangeProvider initialValue={DEFAULT_DATE_RANGE}>
            <ThemeProvider theme={lightTheme}>
              <CssBaseline />
              <AuthSessionProvider>
                <RouterProvider router={router} />
              </AuthSessionProvider>
            </ThemeProvider>
          </DateRangeProvider>
        </NotificationsProvider>
      </LocalizationProvider>
    </Suspense>
  );
}

export default App;
