
export type ErrorCause = {
  type?: string;
  status?: number;
  data?: {[key:string]: any};
};

class HttpError extends Error {
  constructor (message:string, cause?:ErrorCause) {
    super(message);
    this.name = this.constructor.name;
    this.cause = cause;
  }
}

export default HttpError;
