
import { useLayoutEffect, useState } from 'react';
import { listProducts } from '../services/products';
import { ProductType } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export interface ProductsListHook {
  ():[ProductType[], boolean]
}

const useProductsList:ProductsListHook = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ProductType[]>([]);

  useLayoutEffect(() => {
    (async ()=>{
      try {
        setLoading(true);
        setList((await listProducts()).sort((a, b) => {
          if (a.product_name === b.product_name)
            return 0;
          else if (a.product_name > b.product_name)
            return 1;
          else
            return -1;
        }));
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [notify]);

  return [list, loading];
};

export default useProductsList;
