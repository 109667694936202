
import { useContext, useCallback } from 'react';
import authSessionContext from './context';
import permissions from './permissions.json';

export type AccessType = keyof typeof permissions["admin"];

const usePermissions = () => {
  const { user } = useContext(authSessionContext);
  const can = useCallback((accessType:AccessType) => {
    return !!user && permissions[user.role][accessType];
  }, [user]);

  return can;
}

export default usePermissions;
