
import { createContext } from 'react';

export type MainLayoutContext = {
  toolbarElement: HTMLElement | null;
};

const context = createContext<MainLayoutContext>({
  toolbarElement: null
});

export default context;
