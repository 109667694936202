
import apiClient from '../api-client';
import { getAuthUser, setAuthUser } from './store';
import { ResponseData, UserProfile, UserProfileData } from '@ademas/client';

const updateUserProfile = async (data:UserProfileData) => {
  const user = await getAuthUser<UserProfile>();
  if (!user)
    throw new Error('User not logged in.');

  const res = await apiClient.put<ResponseData<UserProfile>>(`/api/v1/users/update?id=${user.id}`, data);
  await setAuthUser(res.data.data);
  return res.data.data;
};

export default updateUserProfile;
