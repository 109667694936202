
import React from 'react';
import { Navigate } from 'react-router-dom';
import usePermissions, { AccessType } from './usePermissions';

export type AccessControlProps = {
  accessType: AccessType;
  redirectTo?: string;
  children?: React.ReactNode;
};

const AccessControl:React.FC<AccessControlProps> = (props) => {
  const {
    accessType,
    redirectTo='/',
    children
  } = props;

  const can = usePermissions();

  return can(accessType) ? <>{children}</> : <Navigate to={redirectTo} />;
}

export default AccessControl;
