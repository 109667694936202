
import React from 'react';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import ReportTitle from '../../@lib/components/@common/ReportTitle';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import DateRangePicker from '../../@lib/components/@common/DateRangePicker';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useState } from 'react';
import { useDateRange } from '../../@lib/components/@common/DateRangePicker';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import usePromotionActivityReport from '../../@lib/hooks/usePromotionActivityReport';
import { exportPromotionActivityReport } from '../../@lib/services/reports';

const PointOfSalesReport:React.FC = () => {
  const { t } = useTranslation(['reports', 'common']);
  const notify = useNotify();
  const range = useDateRange();
  const [data, loading] = usePromotionActivityReport(range);
  const [exportingData, setExportingData] = useState(false);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('data_report', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <ReportTitle>{t('pos.promotor_visits')}</ReportTitle>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          color="secondary"
          disabled={exportingData}
          onClick={async () => {
            try {
              setExportingData(true);
              const [url, filename] = await exportPromotionActivityReport(range.from, range.to);
              const link = document.createElement('a');
              link.setAttribute('href', url);
              link.setAttribute('download', filename);
              link.click();
            }
            catch (err) {
              notify(err as Error);
            }
            finally {
              setExportingData(false);
            }
          }}
        >{t('export', { ns: 'common' })}</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('pos.visiting_area')}</ReportTableCell>
              <ReportTableCell>{t('pos.creation')}</ReportTableCell>
              <ReportTableCell>{t('pos.product_availability')}</ReportTableCell>
              <ReportTableCell>{t('pos.reactivation')}</ReportTableCell>
              <ReportTableCell>{t('pos.reapproved')}</ReportTableCell>
              <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoaded && data.activityStats.rows.map((_, i) => (
              <TableRow key={`${i}`} hover>
                <ReportTableCell>{_.visit_type}</ReportTableCell>
                <ReportTableCell>{_.creation_count}</ReportTableCell>
                <ReportTableCell>{_.products_avaibility}</ReportTableCell>
                <ReportTableCell>{_.reactivation}</ReportTableCell>
                <ReportTableCell>{_.reappro}</ReportTableCell>
                <ReportTableCell>{_.total}</ReportTableCell>
              </TableRow>
            ))}

            {dataLoaded && (
              <TableRow sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('pos.total_visits')}</ReportTableCell>
                <ReportTableCell>{data.activityStats.total.creation_count}</ReportTableCell>
                <ReportTableCell>{data.activityStats.total.products_avaibility}</ReportTableCell>
                <ReportTableCell>{data.activityStats.total.reactivation}</ReportTableCell>
                <ReportTableCell>{data.activityStats.total.reappro}</ReportTableCell>
                <ReportTableCell>{data.activityStats.total.total}</ReportTableCell>
              </TableRow>
            )}

            {dataLoaded && (
              <TableRow>
                <ReportTableCell>{t('pos.percentages')}</ReportTableCell>
                <ReportTableCell>{(data.activityStats.total.creation_count/data.activityStats.total.total*100).toFixed(0)}%</ReportTableCell>
                <ReportTableCell>{(data.activityStats.total.products_avaibility/data.activityStats.total.total*100).toFixed(0)}%</ReportTableCell>
                <ReportTableCell>{(data.activityStats.total.reactivation/data.activityStats.total.total*100).toFixed(0)}%</ReportTableCell>
                <ReportTableCell>{(data.activityStats.total.reappro/data.activityStats.total.total*100).toFixed(0)}%</ReportTableCell>
                <ReportTableCell>{(data.activityStats.total.total/data.activityStats.total.total*100).toFixed(0)}%</ReportTableCell>
              </TableRow>
            )}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={6} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <ReportTitle>{t('pos.developer_performance')}</ReportTitle>
        {/* <Button variant="contained" startIcon={<DownloadIcon />} color="secondary">Export</Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('pos.developer')}</ReportTableCell>
              <ReportTableCell colSpan={2}>{t('pos.num_visits')}</ReportTableCell>
              <ReportTableCell colSpan={2}>{t('pos.num_sales')}</ReportTableCell>
              <ReportTableCell colSpan={2}>{t('pos.num_points')}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <ReportTableCell />
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('made')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('planned')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('made')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('planned')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('made')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('planned')}</ReportTableCell>
            </TableRow>
            {dataLoaded && data.performanceStats.rows.map((_, i) => (
              <TableRow key={`${_.id}${i}`} hover>
                <ReportTableCell>{_.name}</ReportTableCell>
                <ReportTableCell>{_.visits_made}</ReportTableCell>
                <ReportTableCell>{_.visits_planned}</ReportTableCell>
                <ReportTableCell>{_.sales_made}</ReportTableCell>
                <ReportTableCell>{_.sales_planned}</ReportTableCell>
                <ReportTableCell>{_.creations_made}</ReportTableCell>
                <ReportTableCell>{_.creations_planned}</ReportTableCell>
              </TableRow>
            ))}
            {dataLoaded && (
              <TableRow sx={{ backgroundColor: '#fbfbfb' }}>
                <ReportTableCell size="medium">{t('total', { ns: 'common' })}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.visits_made}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.visits_planned}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.sales_made}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.sales_planned}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.creations_made}</ReportTableCell>
                <ReportTableCell>{data.performanceStats.total.creations_planned}</ReportTableCell>
              </TableRow>
            )}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={7} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PointOfSalesReport;
