
import { useState, useLayoutEffect } from 'react';
import { getPromotionActivityReport } from '../services/reports';
import { PromotionActivityStats, AgentPerformanceStats } from '@ademas/client';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../components/NotificationsProvider';

export interface PromotionActivityReportData {
  activityStats: {
    rows: (PromotionActivityStats & { visit_type: string; })[];
    total: PromotionActivityStats;
  }
  performanceStats: {
    rows: AgentPerformanceStats[];
    total: Omit<AgentPerformanceStats, 'id' | 'name'>;
  }
}

export type DateRange = {
  from:Date;
  to:Date;
};

export interface PromotionActivityReportHook {
  (dateRange:DateRange):[(PromotionActivityReportData|null), boolean]
}

const usePromotionActivityReport:PromotionActivityReportHook = (range) => {
  const { t } = useTranslation('reports');
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<PromotionActivityReportData|null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rawData = await getPromotionActivityReport(range.from, range.to);

        // Reformat response.
        const activityStats = {
          rows: [
            { visit_type: t('pos.dakar_visits_total'), ...rawData[0].recap_visits[0].dakar_visits },
            { visit_type: t('pos.out_of_dakar_total'), ...rawData[0].recap_visits[0].out_of_dakar_visits }
          ],
          total: rawData[0].recap_visits[0].total
        };

        const performanceStats = {
          rows: rawData[1].promoter_performances,
          total: {
            visits_made: rawData[1].total.total_visits,
            visits_planned: rawData[1].total.total_visits_planned,
            sales_made: rawData[1].total.total_sales,
            sales_planned: rawData[1].total.total_sales_planned,
            creations_made: rawData[1].total.total_creations,
            creations_planned: rawData[1].total.total_creations_planned
          }
        };

        setReportData({ activityStats, performanceStats });
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [range, t, notify]);

  return [reportData, loading];
};

export default usePromotionActivityReport;
