
import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AccessControl } from './@lib/components/AuthSessionProvider';
import MainLayout from './@lib/components/MainLayout';
import AuthLayout from './@lib/components/AuthLayout';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import OTPVerification from './auth/OTPVerification';
import ResetPassword from './auth/ResetPassword';
import Dashboard from './Dashboard';
import Map from './Map';
import PointOfSalesReport from './reports/PointOfSalesReport';
import HealthServicePointsReport from './reports/HealthServicePointsReport';
import InterpersonalCommunicationsReport from './reports/InterpersonalCommunicationsReport';
import SocialNetworksReport from './reports/SocialNetworksReport';
import TrainingSessionsReport from './reports/TrainingSessionsReport';
import SponsoringReport from './reports/SponsoringReport';
import Users from './setttings/Users';
import Objectives from './setttings/Objectives';
import Account from './Account';

import { getCurrentUser } from './@lib/services/auth';

const router = createBrowserRouter([
  {
    path: 'auth',
    loader: async () => {
      if (await getCurrentUser())
        return redirect('/');
      return true;
    },
    element: <AuthLayout height="100vh" />,
    children: [
      { index: true, element: <Navigate to="/auth/login" /> },
      { path: 'login', element: <Login /> },
      { path: 'forgot', element: <ForgotPassword /> },
      { path: 'verify', element: <OTPVerification /> },
      { path: 'reset', element: <ResetPassword /> }
    ]
  },
  {
    path: '/',
    loader: async () => {
      if (await getCurrentUser())
        return true;
      return redirect('/auth');
    },
    element: <MainLayout height="100vh" />,
    errorElement: <Navigate to="/" />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'map', element: <AccessControl accessType="view:map"><Map /></AccessControl> },
      { path: 'reports', children: [
        { index: true, element: <Navigate to="/reports/point-of-sales" /> },
        { path: 'point-of-sales', element: <AccessControl accessType="view:reports"><PointOfSalesReport /></AccessControl> },
        { path: 'health-service-points', element: <AccessControl accessType="view:reports"><HealthServicePointsReport /></AccessControl> },
        { path: 'interpersonal-communications', element: <AccessControl accessType="view:reports"><InterpersonalCommunicationsReport /></AccessControl> },
        { path: 'social-networks', element: <AccessControl accessType="view:reports"><SocialNetworksReport /></AccessControl> },
        { path: 'training-sessions', element: <AccessControl accessType="view:reports"><TrainingSessionsReport /></AccessControl> },
        { path: 'sponsoring', element: <AccessControl accessType="view:reports"><SponsoringReport /></AccessControl> }
      ]},
      { path: 'settings', children: [
        { index: true, element: <Navigate to="/settings/users" /> },
        { path: 'users', element: <AccessControl accessType="update:users"><Users /></AccessControl> },
        { path: 'objectives', element: <AccessControl accessType="update:objectives"><Objectives /></AccessControl> }
      ]},
      { path: 'account', element: <Account /> }
    ]
  }
]);

export default router;
