
import apiClient from "../api-client";
import { ResponseData, ObjectiveData, Objective } from '@ademas/client';

const updateObjective = async (objectiveId:number, data:ObjectiveData) => {
  const res = await apiClient.put<ResponseData<Objective>>(`/api/v1/objectives/${objectiveId}`, data);
  return res.data.data;
};

export default updateObjective;
