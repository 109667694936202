
import React, { FormEventHandler } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PasswordTextField from '../../@lib/components/@common/PasswordTextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MessageText from '../../@lib/components/@common/MessageText';
import LanguageSwitch from '../../@lib/components/LanguageSwitch';
import AppLogo from '../../@lib/components/AppLogo';

import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import { AuthCredentials } from '../../@lib/services/auth';

const Login:React.FC = () => {
  const { t } = useTranslation(['login', 'common']);
  const { login } = useAuthSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [signingIn, setSigningIn] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleSignin:FormEventHandler<HTMLFormElement> = useCallback(async (ev) => {
    try {
      ev.preventDefault();
      setError(null);
      setSigningIn(true);
      const formData = new FormData(ev.currentTarget);
      const data = Object.fromEntries(formData) as AuthCredentials;
      await login(data.username, data.password);
      navigate('/');
    }
    catch(err) {
      setError(err as Error);
      setSigningIn(false);
    }

  }, [login, navigate]);

  const disabled = signingIn;

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center', pt: { xs: 12, md: 18 }, pb: { xs: 12, md: 0 } }}>
      <Grid container spacing={{ xs: 5, md: 18 }} justifyContent="center">
        <Grid item xs={12} md={5}>
          <Typography variant="h3" color="white" mb={3} mt={{ md: 14 }}>{t('welcome.title')}</Typography>
          <Typography color="white" mb={2}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Excepturi fugit vel obcaecati quas aspernatur officiis voluptatibus perferendis id quod provident.
          </Typography>
          <Typography color="white">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Excepturi fugit vel obcaecati quas aspernatur officiis voluptatibus perferendis id quod provident.
            Sed eum libero reprehenderit sit voluptatibus totam? Voluptates, laboriosam sunt.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ position: 'relative', px: { xs: 2, md: 6 }, py: { xs: 12, md: 14 }, borderRadius: 4 }}>
            <div style={{ textAlign: 'center' }}>
              <AppLogo size="large" />
              <LanguageSwitch
                persistent
                dropDownArrowPosition="left"
                sx={{
                  position: 'absolute',
                  top: 20,
                  right: 20
                }}
              />
            </div>
            <form onSubmit={handleSignin}>
              <FormControl fullWidth sx={{mt: 4, mb: 2}} error={!!error}>
                <FormLabel>{t('username', { ns: 'common' })}</FormLabel>
                <OutlinedInput
                  placeholder=""
                  name="username"
                  type="text"
                  defaultValue={location.state?.username}
                  autoFocus={!location.state?.username}
                  error={!!error}
                  required
                  fullWidth
                  disabled={disabled}
                />
              </FormControl>
              <FormControl fullWidth error={!!error}>
                <FormLabel>{t('password', { ns: 'common' })}</FormLabel>
                <PasswordTextField
                  placeholder={t('password.placeholder', { ns: 'common' })}
                  name="password"
                  autoFocus={!!location.state?.username}
                  error={!!error}
                  required
                  fullWidth
                  disabled={disabled}
                />
              </FormControl>

              <FormGroup row sx={{ px: 1.5, alignItems: 'center', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label={t('remember_me', { ns: 'common' })}
                  disabled={disabled}
                  componentsProps={{
                    typography: {
                      variant: 'caption'
                    }
                  }}
                />

                <Link tabIndex={-1} component={RouterLink} to="/auth/forgot" variant="caption">{t('forgot_password', { ns: 'common' })}</Link>
              </FormGroup>

              <Box mt={5} position="relative">
                <Button
                  type="submit"
                  disabled={disabled}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  {signingIn ? <CircularProgress size={28}/> : t('login', { ns: 'common' })}
                </Button>

                {error && (
                  <MessageText
                    color="error.main"
                    sx={{
                      mt: 2,
                      width: '100%',
                      display: 'block',
                      position: 'absolute'
                    }}
                    typographyProps={{
                      textAlign: 'center'
                    }}
                  >{error.message}</MessageText>
                )}
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
