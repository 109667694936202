
import { createContext } from 'react';

export type GoogleMapContext = {
  map: google.maps.Map|null;
  bounds: google.maps.LatLngBounds|null;
};

const mapContext = createContext<GoogleMapContext>({ map: null, bounds: null });
export default mapContext;
