
import React from 'react';
import logoImage from '../../images/ademas-logo.svg';
import logoImageWhite from '../../images/ademas-logo-white.svg';

export type AppLogoProps = {
  size?: 'small' | 'medium' | 'large' | number;
  variant?: 'color' | 'white';
};

export const LOGO_SIZE_MAP = {
  'small': 48,
  'medium': 56,
  'large': 72
};

const AppLogo:React.FC<AppLogoProps> = (props) => {
  const {
    size='medium',
    variant='color'
  } = props;

  const height = typeof size === 'string' ? LOGO_SIZE_MAP[size] : size;

  return (
    <img src={variant === 'color' ? logoImage : logoImageWhite} alt="Ademas logo mark" height={height}/>
  );
};

export default AppLogo;
