
import React, { useMemo } from 'react';
import AdminDashboard from './AdminDashboard';
import PromoterDashboard from './PromoterDashboard';
import MedicalVisitorDashboard from './MedicalVisitorDashboard';
import { Navigate } from 'react-router-dom';
import { useAuthSession } from '../@lib/components/AuthSessionProvider';

const Dashboard:React.FC = () => {
  const { user } = useAuthSession();
  const dashboard = useMemo(() => {
    if (!user)
      return null;

    switch (user.role) {
      case 'admin':
      case 'manager':
      case 'supervisor':
      case 'internal_agent':
        return <AdminDashboard />;
      case 'medical_visitor':
        return <MedicalVisitorDashboard />;
      case 'promoter':
        return <PromoterDashboard />;
      default:
        return <Navigate to="/account" />;
    }
  }, [user]);

  return dashboard;
};

export default Dashboard;
