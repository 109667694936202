
import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { useState, useMemo, useCallback, useRef, createRef } from 'react';
import styled from '@mui/material/styles/styled';

const Input = styled(OutlinedInput)({
  ".MuiInputBase-input": {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 700
  }
});

export type SplitTextFieldProps = OutlinedInputProps & {
  totalParts?: number;
  partLength?: number;
};

const SplitTextField:React.FC<SplitTextFieldProps> = (props) => {
  const {
    totalParts=6,
    partLength=1,
    fullWidth,
    autoFocus,
    name,
    ...inputProps
  } = props;

  const valueEleRef = useRef<HTMLInputElement>(null);
  const [valueParts, setValueParts] = useState<string[]>(()=>((new Array(totalParts)).fill('')));

  const inputRefs = useMemo(() => {
    return (new Array(Math.abs(totalParts))).fill('').map(()=>createRef<HTMLInputElement>());
  }, [totalParts]);

  const value = useMemo(() => {
    return valueParts.reduce((val, part) => {
      return val += part;
    }, '');
  }, [valueParts]);

  const setValue = useCallback((value:string, partIndex:number) => {
    setValueParts(currValues => {
      const values = [...currValues];
      values[partIndex] = value;
      return values;
    });
  }, []);

  const handleInput:ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
    const value = (ev.target.value || "").trim();
    const currFocusIndex = parseInt(ev.target.dataset.index || '0');

    if (!value || value === "")
      return ev.target.value = "";

    if (value.length === partLength)
      setValue(value, currFocusIndex);

  }, [partLength, setValue]);

  const handleKeyUp:KeyboardEventHandler<HTMLInputElement> = useCallback((ev) => {
    const currFocusIndex = parseInt(ev.currentTarget.dataset.index || '0');
    const value = (ev.currentTarget.value || "").trim();
    const key = ev.key.toLocaleLowerCase();
    if (key === 'backspace' || key === 'delete') {
      if (value.length === 0 && currFocusIndex > 0) {
        inputRefs[currFocusIndex-1].current?.focus();
      }
    }
    else if (value.length === partLength && currFocusIndex + 1 !== totalParts)
      inputRefs[currFocusIndex + 1].current?.focus();

  }, [partLength, totalParts, inputRefs]);

  return (
    <Box display={fullWidth ? 'flex' : 'inline-flex'} flexWrap="nowrap" gap={1}>
      <input type="hidden" ref={valueEleRef} name={name} value={value} />
      {inputRefs.map((ref, i) => (
        <Input
          key={`${i}`}
          {...inputProps}
          autoFocus={autoFocus && i === 0}
          onInput={handleInput}
          onKeyUp={handleKeyUp}
          inputProps={{
            ref,
            maxLength: partLength,
            "data-index": i
          }}
        />
      ))}
    </Box>
  )
};

export default SplitTextField;
