
import React from 'react';
import MainToolbar from '../../@lib/components/MainToolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableRowSkeleton from '../../@lib/components/@common/TableRowSkeleton';
import ReportTableCell from '../../@lib/components/@common/ReportTableCell';
import ReportTitle from '../../@lib/components/@common/ReportTitle';
import DateRangePicker from '../../@lib/components/@common/DateRangePicker';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useState } from 'react';
import { useDateRange } from '../../@lib/components/@common/DateRangePicker';
import { useNotify } from '../../@lib/components/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import useTrainingSessionsReport from '../../@lib/hooks/useTrainingSessionsReport';
import { exportTrainingSessionsReport } from '../../@lib/services/reports';

const TrainingSessionsReport:React.FC = () => {
  const { t } = useTranslation(['reports', 'common']);
  const notify = useNotify();
  const range = useDateRange();
  const [data, loading] = useTrainingSessionsReport(range);
  const [exportingData, setExportingData] = useState(false);

  const dataLoaded = !loading && !!data;

  return (
    <Container sx={{ pt: 3 }}>
      <MainToolbar title={t('data_report', { ns: 'common' })}>
        <div style={{ flexGrow: 1 }} />
        <DateRangePicker />
      </MainToolbar>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <ReportTitle>{t('training.summary.activities')}</ReportTitle>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          color="secondary"
          disabled={exportingData}
          onClick={async () => {
            try {
              setExportingData(true);
              const [url, filename] = await exportTrainingSessionsReport(range.from, range.to);
              const link = document.createElement('a');
              link.setAttribute('href', url);
              link.setAttribute('download', filename);
              link.click();
            }
            catch (err) {
              notify(err as Error);
            }
            finally {
              setExportingData(false);
            }
          }}
        >{t('export', { ns: 'common' })}</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <ReportTableCell width={200}>{t('target')}</ReportTableCell>
              <ReportTableCell width={200}>{t('trainer')}</ReportTableCell>
              <ReportTableCell colSpan={4}>{t('women')}</ReportTableCell>
              <ReportTableCell colSpan={4}>{t('men')}</ReportTableCell>
              <ReportTableCell colSpan={4}>{t('disabled')}</ReportTableCell>
              <ReportTableCell>{t('total', { ns: 'common' })}</ReportTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <ReportTableCell colSpan={2} />
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('15_18_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('19_24_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('25_49_yr')}</ReportTableCell>
              <ReportTableCell fontStyle="italic" opacity={0.5}>{t('total', { ns: 'common' })}</ReportTableCell>
              <ReportTableCell />
            </TableRow>
            {dataLoaded && data.map((_, i) => (
              typeof _.cible !== 'undefined' ? (
                _.data.map((training, j) => (
                  <TableRow key={`${i}-${j}`} hover>
                    {j === 0 && <ReportTableCell rowSpan={_.data.length}>{_.cible}</ReportTableCell>}
                    <ReportTableCell>{training.trainer}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(0)?.data?.at(0)?.female_15_18}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(0)?.data?.at(0)?.female_19_24}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(0)?.data?.at(0)?.female_25_49}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(0)?.data?.at(0)?.total}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(1)?.data?.at(0)?.male_15_18}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(1)?.data?.at(0)?.male_19_24}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(1)?.data?.at(0)?.male_25_49}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(1)?.data?.at(0)?.total}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(2)?.data?.at(0)?.disabled_15_18}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(2)?.data?.at(0)?.disabled_19_24}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(2)?.data?.at(0)?.disabled_25_49}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(2)?.data?.at(0)?.total}</ReportTableCell>
                    <ReportTableCell>{training.data?.at(3)?.total_cible}</ReportTableCell>
                  </TableRow>
                ))
              ):(
                <TableRow key={`${i}`} sx={{ backgroundColor: '#fbfbfb' }}>
                  <ReportTableCell size="medium" colSpan={2}>{_.name}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_female_15_18}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_female_19_24}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_female_25_49}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_female}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_male_15_18}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_male_19_24}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_male_25_49}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_male}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_disabled_15_18}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_disabled_19_24}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_disabled_25_49}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.total_disabled}</ReportTableCell>
                  <ReportTableCell>{_.data.at(0)?.global_total}</ReportTableCell>
                </TableRow>
              )
            ))}

            {loading && <TableRowSkeleton rowsCount={3} cellsCount={15} tableCellComponent={ReportTableCell} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TrainingSessionsReport;
