
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#333C7B',
      light: '#515B9D'
    },
    secondary: {
      main: '#897CBA'
    },
    background: {
      default: '#F5F5F5'
    }
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    h3: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 600
    }
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        outlined: {
          borderColor: '#F4F2F2'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#ffffff'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: 32,
          paddingTop: 4,
          paddingBottom: 4
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
        variant: 'contained',
        color: 'secondary',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 40,
          paddingBlock: 10,
          paddingInline: 40,
          fontSize: 16,
          fontWeight: 700
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          '&.Mui-error': {
            color: '#d32f2f'
          },
          '& .MuiInputBase-input': {
            paddingLeft: 22,
            paddingRight: 22,
            paddingTop: 14,
            paddingBottom: 14
          }
        }
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'inherit'
          }
        },
        icon: {
          marginRight: 8
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 2.15,
          paddingLeft: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem'
        }
      }
    }
  }
});

export default theme;
