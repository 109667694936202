
import React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import bgImage from '../../images/bg-circles-blue.jpg';

export type AuthLayoutProps = {
  height?: number | string;
};

const AuthLayout:React.FC<AuthLayoutProps> = (props) => {
  const {
    height='100%'
  } = props;

  return (
    <Box sx={{
      background: `url(${bgImage}) center center`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      minHeight: height
    }}>
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
