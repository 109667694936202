
import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
import { NavLink, NavLinkProps } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/PinDrop';
import DataReportIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../NotificationsProvider';
import { styled } from '@mui/material/styles';
import { useAuthSession, usePermissions } from '../AuthSessionProvider';

export type MainSideNavProps = BoxProps;

export type NavItem = {
  path: string;
  label: string;
  icon?: React.ReactNode,
  children?: NavItem[]
};

export const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps & NavLinkProps>(({ theme })=>({
  '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    color: theme.palette.primary.contrastText
  },
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.05)'
  },
  '&.active': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'rgba(0,0,0,0.03) 0 3px 4px 0'
  }
}));

export const SubListItemButton = styled(MuiListItemButton)<ListItemButtonProps & NavLinkProps>(({ theme })=>({
  '&:hover': {
    backgroundColor: 'unset'
  },
  '& .MuiListItemText-root': {
    transition: 'all 250ms',
    borderBottom: '1px solid transparent',
    color: '#7480cd'
  },
  '&:hover .MuiListItemText-root': {
    borderColor: '#7480cd'
  },
  '&.active .MuiListItemText-root': {
    color: theme.palette.primary.contrastText,
    borderColor: '#F5F5F5'
  }
}));

const MainSideNav:React.FC<MainSideNavProps> = (props) => {
  const {
    ...boxProps
  } = props;

  const { t } = useTranslation(['dashboard', 'common']);
  const can = usePermissions();
  const notify = useNotify();
  const navigate = useNavigate();
  const { logout } = useAuthSession();
  const [signingOut, setSigningOut] = useState(false);

  const navItems = useMemo(() => {
    const items:NavItem[] = [
      { path: '/', label: t('dashboard', { ns: 'common' }), icon: <DashboardIcon />}
    ];

    can('view:map') && items.push({ path: '/map', label: t('map', { ns: 'common' }), icon: <MapIcon /> });
    can('view:reports') && items.push({ path: '/reports', label: t('data_report', { ns: 'common' }), icon: <DataReportIcon />, children: [
      { path: '/reports/point-of-sales', label: t('pos.label') },
      { path: '/reports/health-service-points', label: t('health_points.label') },
      { path: '/reports/interpersonal-communications', label: t('interpersonal_comm.label') },
      { path: '/reports/social-networks', label: t('social_net.label') },
      { path: '/reports/training-sessions', label: t('training_sess.label') },
      { path: '/reports/sponsoring', label: t('sponsoring.label') }
    ]});
    can('update:users') && can('update:objectives') && items.push({ path: '/settings', label: t('settings', { ns: 'common' }), icon: <SettingsIcon />, children: [
      { path: '/settings/users', label: t('users', { ns: 'common' }) },
      { path: '/settings/objectives', label: t('objectives', { ns: 'common' }) }
    ]});

    return items;
  }, [t, can]);

  const handleSignout = useCallback(async () => {
    try {
      setSigningOut(true);
      await logout();
      navigate('/auth/login');
    }
    catch(err) {
      notify(err as Error);
      setSigningOut(false);
    }

  }, [logout, navigate, notify]);

  return (
    <Box {...boxProps}>
      <Typography variant="caption" pl={4}>{t('navigation', { ns: 'common' })}</Typography>
      <List disablePadding>
        {navItems.map((item)=>(
          <React.Fragment key={item.label}>
            <ListItemButton
              component={NavLink}
              to={item.path}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 1, color: '#99a1b7' }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontWeight: 600
                }}
              />
            </ListItemButton>
            {item.children && item.children.length > 0 && (
              <List disablePadding>
                {item.children.map((item) => (
                  <SubListItemButton
                    key={item.label}
                    component={NavLink}
                    to={item.path}
                    disableRipple
                    sx={{ pl: 8 }}
                  >
                    <ListItemText
                      primary={item.label}
                    />
                  </SubListItemButton>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>

      <List sx={{ mt: 4 }}>
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/account">
            <ListItemIcon sx={{ minWidth: 0, mr: 1, color: '#99a1b7' }}><AccountIcon /></ListItemIcon>
            <ListItemText
              primary={t('my_account', { ns: 'common' })}
              primaryTypographyProps={{
                color: 'black',
                fontWeight: 600
              }}
            />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ mx: 3, borderColor: 'rgba(255,255,255,0.75)' }} />
        <ListItem disablePadding>
          <MuiListItemButton
            onClick={handleSignout}
            disabled={signingOut}
            sx={{ pl: 4, py: 0.5 }}
          >
            <ListItemIcon
              sx={{ minWidth: 0, mr: 1, color: 'primary.contrastText' }}
            >
              {signingOut ? <CircularProgress size={22} color="inherit"/> : <LogoutIcon />}
            </ListItemIcon>
            <ListItemText
              primary={t('sign_out', { ns: 'common' })}
              primaryTypographyProps={{
                // color: 'error',
                fontWeight: 600
              }}
            />
          </MuiListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default MainSideNav;
