
import apiClient from "../api-client";
import { ResponseData, Objective } from '@ademas/client';

const listObjectives = async () => {
  const res = await apiClient.get<ResponseData<Objective[]>>('/api/v1/objectives');
  return res.data.data;
};

export default listObjectives;
