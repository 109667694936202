
import apiClient from '../api-client';
import { ResponseData, UserProfile } from '@ademas/client';

const listUsers = async () => {
  const res = await apiClient.get<ResponseData<UserProfile[]>>('/api/v1/users');
  return res.data.data;
};

export default listUsers;
