
import { useState, useLayoutEffect } from 'react';
import { getSiteVisitActivityReport } from '../services/reports';
import { SiteVisitActivityReport } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

// export type RegionVisitsCountMap = {
//   [region:string]: number;
// };

// export interface SiteVisitActivityStatsByRegion {
//   type: string;
//   mapping: RegionVisitsCountMap;
//   totalVisits: number;
// };

// export interface SiteVisitActivityReportData {
//   structureTypes: {
//     regions: string[];
//     rows: SiteVisitActivityStatsByRegion[];
//     total: Omit<SiteVisitActivityStatsByRegion, 'type'>;
//   }
//   roles: {
//     regions: string[];
//     rows: SiteVisitActivityStatsByRegion[];
//     total: Omit<SiteVisitActivityStatsByRegion, 'type'>;
//   }
// }

export type SiteVisitActivityReportData = SiteVisitActivityReport;

export type DateRange = {
  from:Date;
  to:Date;
};

export interface SiteVisitActivityReportHook {
  (dateRange:DateRange):[(SiteVisitActivityReportData|null), boolean]
}

// function parseReport (siteTypes:SiteVisitActivityReport["type_structures"]) {
//   const regions = new Set<string>();
//   const totalRegionVisitsMapping:RegionVisitsCountMap = {};
//   let totalVisits = 0;

//   const rows = siteTypes.reduce((_rows, site) => {
//     const regionVisitsMapping:RegionVisitsCountMap = {};
//     let totalSiteTypeVisits = 0;
//     site.data.forEach((stat) => {
//       if (typeof stat.total === 'number') {
//         totalSiteTypeVisits = stat.total || 0;
//         return;
//       }
//       else if (typeof stat.region === 'string') {
//         const region = stat.region.toLocaleLowerCase();
//         const count = stat.count || 0;
//         regions.add(region);
//         regionVisitsMapping[region] = count;

//         // Add to total region visits mapping
//         const currTotal = totalRegionVisitsMapping[region] || 0;
//         totalRegionVisitsMapping[region] = currTotal + count;
//         totalVisits += count;
//       }
//     });

//     _rows.push({
//       type: site.name,
//       mapping: regionVisitsMapping,
//       totalVisits: totalSiteTypeVisits
//     });

//     return _rows;
//   }, [] as SiteVisitActivityStatsByRegion[]);

//   return {
//     regions: Array.from(regions).sort(),
//     rows: rows.sort((a, b) => {
//       if (a.type < b.type)
//         return -1;
//       else if (a.type > b.type)
//         return 1;

//       return 0;
//     }),
//     total: {
//       mapping: totalRegionVisitsMapping,
//       totalVisits: totalVisits
//     }
//   };
// }

const useSiteVisitActivityReport:SiteVisitActivityReportHook = (dateRange) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<SiteVisitActivityReportData|null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rawData = await getSiteVisitActivityReport(dateRange.from, dateRange.to);
        setReportData(rawData);

        // Reformat response.
        // const medicalStructureVisits = parseReport(rawData.type_structures);
        // const medicalFunctionVisits = parseReport(rawData.type_fonctions);        

        // setReportData({
        //   structureTypes: medicalStructureVisits,
        //   roles: medicalFunctionVisits
        // });
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [dateRange, notify]);

  return [reportData, loading];
};

export default useSiteVisitActivityReport;
