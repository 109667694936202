
import apiClient from '../api-client';
import { UserPasswordData } from '@ademas/client';

const updatePassword = async (newPassCreds:UserPasswordData) => {
  await apiClient.put('/api/v1/users/update_password', newPassCreds);
  return;
};

export default updatePassword;
