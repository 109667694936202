
import apiClient from '../api-client';
import { getAuthUser, getAccessToken } from "./store";
import { UserProfile } from '@ademas/client';

const getCurrentUser = async () => {
  const user = await getAuthUser<UserProfile>();
  if (user && !apiClient.defaults.headers.common.Authorization)
    apiClient.defaults.headers.common.Authorization = await getAccessToken();

  return user;
};

export default getCurrentUser;
