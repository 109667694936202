
import { createContext } from "react";
import { OptionsObject } from 'notistack';

export interface NotifyFunction {
  (msg:string|Error, options?:OptionsObject):void;
}

const context = createContext<NotifyFunction>(alert);
export default context;
