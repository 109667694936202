
import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import MuiButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomRangePickerDialog from './CustomRangePickerDialog';

import { useState, useCallback } from 'react';
import { useDateRange } from './DateRangeProvider';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

export type DateRangeOptions = 'today' | 'this-week' | 'this-month' | 'this-year';

export interface DateRangePickerProps {
  options?: DateRangeOptions[];
}

const Button = styled(MuiButton)(({theme}) => ({
  paddingBlock: theme.spacing(0.5),
  paddingInline: theme.spacing(2),
  borderColor: '#F4F2F2',
  color: theme.palette.text.primary,
  fontWeight: 400
}));

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const {
    options=['today', 'this-week', 'this-month', 'this-year']
  } = props;

  const dateRange = useDateRange();
  const [label, setLabel] = useState(() => {
    const todayDate = new Date().toDateString();
    if (dateRange.from.toDateString() === todayDate && dateRange.to.toDateString() === todayDate)
      return 'Today';
    return `${dayjs(dateRange.from).format('DD MMM YYYY')} - ${dateRange.to.toDateString() === todayDate ? 'Today' : dayjs(dateRange.to,).format('DD MMM YYYY')}`;
  });
  const [custDialogOpen, setCustDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleChange = useCallback((rangeType: DateRangeOptions)=>{
    let from:Date = new Date(), to:Date = new Date();
    // Fix time component from the range. set 12am for "from" date and 11:59pm for "to" date.
    from = new Date(from.toDateString());
    to = new Date(to.toDateString());
    to.setDate(to.getDate() + 1);
    to.setMilliseconds(-1);

    switch (rangeType) {
      case 'this-week':
        from.setDate(from.getDate() - from.getDay());
        setLabel(`${dayjs(from).format('DD MMM YYYY')} - Today`);
        break;
      case 'this-month':
        from.setDate(1);
        setLabel(`${dayjs(from).format('DD MMM YYYY')} - Today`);
        break;
      case 'this-year':
        from.setMonth(0);
        from.setDate(1);
        setLabel(`${dayjs(from).format('DD MMM YYYY')} - Today`);
        break;
      case 'today':
      default:
        setLabel(`Today`);
        break;
    }

    dateRange.update(from, to);
    setAnchorEl(null)
  }, [dateRange]);

  return (
    <>
      <ButtonGroup
        color="secondary"
      >
        <Tooltip title={`${dayjs(dateRange.from).format('DD MMM YYYY @hh:mm A')} - ${dayjs(dateRange.to).format('DD MMM YYYY @hh:mm A')}`}>
          <Button
            startIcon={<DateRangeIcon />}
            style={{ textTransform: 'initial' }}
            onClick={()=>setCustDialogOpen(true)}
            variant="outlined"
            color="inherit"
            disableElevation
          >
            {label}
          </Button>
        </Tooltip>
        <Button
          onClick={ev=>setAnchorEl(ev.currentTarget)}
          sx={{ padding: 0 }}
          variant="outlined"
          color="inherit"
          disableElevation
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={()=>setAnchorEl(null)}
      >
        {options.length && options.map((op, i) => (
          <MenuItem
            key={`${op}-${i}`}
            style={{ textTransform: 'capitalize' }}
            onClick={()=>handleChange(op)}
          >
            {op.replace('-', ' ')}
          </MenuItem>
        ))}
      </Menu>

      <CustomRangePickerDialog
        open={custDialogOpen}
        initialValues={dateRange}
        onDone={range=>{
          dateRange.update(range.from, range.to);
          const todayDate = new Date().toDateString();
          const fromDate = range.from.toDateString();
          const toDate = range.to.toDateString();
          const fromLabel = fromDate === todayDate ? 'Today' : dayjs(range.from).format('DD MMM YYYY');
          const toLabel = toDate === todayDate ? 'Today' : dayjs(range.to).format('DD MMM YYYY');
          setLabel(fromLabel === toLabel ? fromLabel : `${fromLabel} - ${toLabel}`)
          setCustDialogOpen(false);
        }}
        onCancel={()=>setCustDialogOpen(false)}
      />
    </>
  );
};

export default DateRangePicker;
