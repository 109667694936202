
import React from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import UpArrowIcon from '@mui/icons-material/ArrowUpward';
import DownArrowIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/material/styles';

export type StatProps = StackProps;

const Stat:React.FC<StatProps> = (props) => {
  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      {...props}
      direction="column"
    />
  );
};

export default Stat;

/**
 * BEGIN: StatNumber
 */
export const StatNumber = styled('div')(({ theme }) => ({
  ...theme.typography.h2,
  color: '#565656',
  fontWeight: 700
}));

/**
 * BEGIN: StatChange
 */
export type StatChangeProps = TypographyProps & {
  type: 'increase' | 'decrease';
};

export const StatChange:React.FC<StatChangeProps> = (props) => {
  const {
    type,
    children,
    ...typographyProps
  } = props;

  return (
    <Typography
      variant="h6"
      fontWeight={500}
      {...typographyProps}
      color={type === 'increase' ? '#3DD243' : '#CD4646'}
    >
      <span style={{ verticalAlign: 'middle' }}>{type === 'increase' ? <UpArrowIcon /> : <DownArrowIcon />}</span>{children}
    </Typography>
  );
};

/**
 * BEGIN: StatHelpText
 */
export const StatHelpText:React.FC<TypographyProps> = (props) => {
  return (
    <Typography textAlign="center" fontWeight={600} {...props} variant="body2" color="#868686" />
  );
};
