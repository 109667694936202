
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import MainSideNav from '../MainSideNav';
import LanguageSwitch from '../LanguageSwitch';
import AppLogo from '../AppLogo';
import { Outlet } from 'react-router-dom';

import { useState, useEffect, useRef } from 'react';
import context from './context';

export type MainLayoutProps = {
  height?: number | string;
};

export const DRAWER_WIDTH = 250;
export const APPBAR_HEIGHT = 64;
export const APPBAR_HEIGHT_XS = 56;

const MainLayout:React.FC<MainLayoutProps> = (props) => {
  const {
    height='100%'
  } = props;

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarElement, setToolbarElement] = useState<HTMLElement|null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  
  useEffect(() => {
    if (toolbarRef.current !== null)
      setToolbarElement(toolbarRef.current);
  }, []);

  return (
    <context.Provider value={{ toolbarElement }}>
      <Box sx={{ height }}>
        {/* BEGIN: AppBar */}
        <AppBar
          position="fixed"
          color="default"
          sx={{
            flexDirection: 'row',
            zIndex: (theme)=>theme.zIndex.drawer - 1,
            // color: (theme)=>theme.palette.text.primary
          }}
          elevation={0}
        >
          
          <Box
            pl={{ xs: 2, sm: 3 }}
            sx={{
              width: { md: DRAWER_WIDTH },
              flexShrink: 0,
              lineHeight: 0,
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center'
            }}
          >
            <IconButton
              edge="start"
              onClick={()=>setMobileOpen(true)}
              sx={{  }}
            >
              <MenuIcon />
            </IconButton>
            {/* TODO: Use Logo mark instead. */}
            <AppLogo size="small" />
          </Box>

          <Toolbar ref={toolbarRef} component="div" sx={{ flexGrow: 1, ml: { xs: 0, md: DRAWER_WIDTH/8 } }} />
        </AppBar>
        {/* END: AppBar */}

        {/* BEGIN: Drawer Mobile Screen */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={()=>setMobileOpen(false)}
          onClick={()=>setMobileOpen(false)}
          sx={{ width: DRAWER_WIDTH, display: { xs: 'block', md: 'none' } }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              border: 'none',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText'
            }
          }}
        >
          <Box pl={4} pr={2} pt={3} lineHeight={0}>
            <AppLogo
              variant="white"
            />
          </Box>

          <MainSideNav
            mt={3}
            sx={{ flexGrow: 1, overflow: 'auto' }}
          />
        </Drawer>
        {/* END: Drawer Mobile Screen */}

        {/* BEGIN: Drawer Desktop Screen */}
        <Drawer
          variant="permanent"
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            display: { xs: 'none', md: 'block' }
          }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              border: 'none',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText'
            }
          }}
        >
          <Box pl={4} pr={2} py={3} lineHeight={0}>
            <AppLogo
              variant="white"
            />
          </Box>

          <MainSideNav
            mt={1}
            sx={{ flexGrow: 1, overflow: 'auto' }}
          />

          <Box p={2}>
          </Box>
        </Drawer>
        {/* END: Drawer Desktop Screen */}

        {/* BEGIN: Main Content */}
        <Box
          component="main"
          pt={{ xs: APPBAR_HEIGHT_XS/8, sm: APPBAR_HEIGHT/8 }}
          pl={{ xs: 0, md: DRAWER_WIDTH/8 }}
          sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Box flexGrow={1} display="flex" flexDirection="column" position="relative">
            <Outlet />
          </Box>

          <Box component={Container} sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption">
              <b style={{ color: '#99a1b7' }}>{(new Date()).getFullYear()}&copy;</b> Ademas
            </Typography>
            
            <LanguageSwitch
              persistent
              sx={{ ml: 1 }}
            />

            <Box sx={{ flexGrow: 1 }}/>
          </Box>
        </Box>
        {/* END: Main Content */}
      </Box>
    </context.Provider>
  );

  /**
   * MUI Grid based layout.
   */
  
  // return (
  //   <Grid container height={height} minHeight={minHeight}>
  //     <Grid item xs="auto">
  //       <MainSideNav />
  //     </Grid>
  //     <Grid container item direction="column" xs>
  //       <Grid item xs="auto">
  //         <MainToolbar />
  //       </Grid>
  //       <Grid item xs overflow="auto">
  //         <Outlet />
  //       </Grid>
  //     </Grid>      
  //   </Grid>
  // );
};

export default MainLayout;
