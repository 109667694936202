
import React from 'react';
import { UserProfile } from '@ademas/client';
import { useState, useMemo, useLayoutEffect } from 'react';
import { useNotify } from '../NotificationsProvider';
import context, { AuthSessionContext } from './context';
import { getCurrentUser, signinUser, signoutUser } from '../../services/auth';

export type AuthSessionProviderProps = {
  children?: React.ReactNode;
};

const AuthSessionProvider:React.FC<AuthSessionProviderProps> = (props) => {
  const notify = useNotify();
  const [user, setUser] = useState<UserProfile|null>(null);
  const [loading, setLoading] = useState(false);

  const sessionData:AuthSessionContext = useMemo(() => ({
    user,
    login: (username, password) => {
      if (user)
        return Promise.resolve();
      
      return signinUser({ username, password })
        .then((user) => setUser(user))
        .catch((err) => notify(err))
      ;
    },
    logout: () => {
      return signoutUser()
        .then(() => setUser(null))
        .catch((err) => {
          signoutUser(true)
            .then(() => setUser(null))
          ;
        })
      ;
    }
  }), [user, notify]);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const user = await getCurrentUser();
        setUser(user);
      }
      catch (err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [notify]);

  return (
    <context.Provider
      value={sessionData}
    >{!loading && props.children}</context.Provider>
  );
};

export default AuthSessionProvider;
