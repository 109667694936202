
import { useState, useLayoutEffect } from 'react';
import { getSponsoringActivityReport } from '../services/reports';
import { SponsoringActivityReport } from '@ademas/client';
import { useNotify } from '../components/NotificationsProvider';

export type SponsoringActivityReportData = SponsoringActivityReport;

export type DateRange = {
  from:Date;
  to:Date;
};

export interface SponsoringActivityReportHook {
  (dateRange:DateRange):[(SponsoringActivityReportData|null), boolean]
}

const useSponsoringActivityReport:SponsoringActivityReportHook = (dateRange) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<SponsoringActivityReportData|null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rawData = await getSponsoringActivityReport(dateRange.from, dateRange.to);
        setReportData(rawData);
      }
      catch(err) {
        notify(err as Error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [dateRange, notify]);

  return [reportData, loading];
};

export default useSponsoringActivityReport;
