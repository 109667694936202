
import { createContext } from 'react';
import { UserProfile } from '@ademas/client';

export type AuthSessionContext = {
  user: UserProfile|null;
  login: (username:string, password:string)=>Promise<void>;
  logout: ()=>Promise<void>;
};

const DEFAULT_CONTEXT = {
  user: null,
  login: async () => alert('AuthSessionProvider: Context inaccessible.'),
  logout: async () => alert('AuthSessionProvider: Context inaccessible.')
};

const context = createContext<AuthSessionContext>(DEFAULT_CONTEXT);
export default context;
